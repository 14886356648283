.law {
  padding: 46px 0 90px 0;
  background: url("/img/content-bg.png") 100% 0 no-repeat;
}

.law-wrapper {
}

.title {
  width: 878px;
}

.main-title {
  color: #06297B;
  font-size: 3.125rem;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 19px;
  margin-top: 0;
}

.line-title {
  width: 120px;
  height: 10px;
  background: #06297B;
}

.law-content {
  color: #000;
  font-size: 1.25rem;
  line-height: normal;
  margin-top: 46px;
}

@media (max-width: 1300px) {
  .title {
    width: 100%;
  }
}

@media (max-width: 992px) {
  .main-title {
    font-size: 1.875rem;
  }
}
