.main-news {
  padding: 50px 0 95px;
}

.main-news-title .main-title {
  color: #000;
}

.main-news-title .line-title {
  background: #000;
  height: 5px;
}

.main-news-content {
  margin-top: 50px;
}

.main-news-content .block-cards {
  margin-bottom: 50px;
}

.main-link {
  color: #000;
  font-size: 1.25rem;
  font-weight: 700;
}

.main-link:hover {
  color: #000 !important;
}

//banner
.banner {
}
.banner-wrapper {
  .carousel {
  }
  .slide {
  }
  .carousel-inner {
  }
  .carousel-item {
  }
  .active {
  }
  .banner-block {
    padding: 128px 0 130px;
    background: url("/img/banner-img.webp") no-repeat, lightgray 50% / cover no-repeat;
    background-size: cover;
  }
  .my-container {
  }
  .title {
  }
  .main-title {
    color: #fff;
  }
  .line-title {
    background: #D9D9D9;
  }
  .main-text {
    margin-top: 50px;
    color: #FFF;
    font-size: 1.25rem;
    max-width: 42%;
    margin-bottom: 50px;
  }
  .main-text p{
    margin-bottom: 8px;
  }
  .carousel-control-prev,
  .carousel-control-next {
    display: inline-block;
    width: auto;
    top: inherit;
    position: unset;
    opacity: 1;
  }
  .carousel-control-prev-icon {
    display: none;
  }
  .visually-hidden {
  }
  .carousel-control-next {
  }
  .carousel-control-next-icon {
    display: none;
  }
}

@media (max-width: 1200px) {
  .banner-wrapper {
    .main-text {
      max-width: 60%;
    }
  }
}

@media (max-width: 992px) {
  .banner-wrapper {
    .banner-block {
      background-size: cover;
      background-position: center;
    }

    .main-text {
      max-width: 80%;
    }
  }

}

@media (max-width: 768px) {
  .banner-wrapper {
    .main-text {
      max-width: 100%;
    }
  }
}
//advocate
.advocate {
  color: #1D1D1D;
  padding: 50px 0;
}

.advocate-wrapper {

  .carousel {
  }
  .slide {
  }
  .carousel-inner {
  }
  .carousel-item {
  }
  .active {
  }
  .advocate-block {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
    align-items: center;
  }
  .advocate-img-block {
    width: 100%;
    border-right: 10px;
  }
  .advocate-img {
    width: 100%;
    border-right: 10px;
  }
  .advocate-block-content {
    position: relative;
    font-weight: 700;
    font-size: 1.25rem;
  }
  .advocate-block-title {
    margin-bottom: 22px;
    font-size: 2.5rem;
  }
  .advocate-block-sub-title {
    font-size: 1.875rem;
    margin-bottom: 16px;
  }
  .advocate-block-line {
    width: 120px;
    height: 5px;
    background: #1D1D1D;
    margin-bottom: 22px;
  }
  .advocate-block-text {
    font-weight: 400;
    margin-bottom: 22px;
  }
  .advocate-block-link {
    display: block;
    color: #1D1D1D;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 22px;
  }
  .carousel-control-prev,
  .carousel-control-next {
    display: inline-block;
    width: auto;
    top: inherit;
    opacity: 1;
    position: unset;
  }
  .carousel-control-prev-icon {
    display: none;
  }
  .visually-hidden {
  }
  .carousel-control-next {
  }
  .carousel-control-next-icon {
    display: none;
  }
}

@media (max-width: 1200px) {
  .advocate-wrapper {
    .advocate-block {
      grid-template-columns: 1fr;
    }
    .advocate-img-block {
      width: min-content;
      margin: 0 auto;
    }
    .advocate-img {
      width: min-content;
    }
  }
}

@media (max-width: 768px) {
  .advocate-wrapper {
    .advocate-img-block {
      width: 100%;
    }
    .advocate-img {
      width: 100%;
    }
  }
}

//basic-sections

.basic-sections {
  padding: 50px 0 100px;
}

.basic-sections-title .main-title {
  color: #000;
}

.basic-sections-title .line-title {
  background: #000;
  height: 5px;
}

.basic-sections-content {
  margin-top: 50px;

  // .basic-sections-content__boxes

  &__boxes {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    margin-bottom: 16px;
  }

  // .basic-sections-content__box

  &__box {
    display: flex;
    align-items: end;
    border-radius: 10px;
    height: 397px;
    text-decoration: none;
  }

  &__box:hover{
    text-decoration: none!important;
  }

  &__box:nth-child(1) {
    background: url("/img/basic-img-1.webp") no-repeat, lightgray 50% / cover no-repeat;
  }

  &__box:nth-child(2) {
    background: url("/img/basic-img-2.webp") no-repeat, lightgray 50% / cover no-repeat;
  }

  // .basic-sections-content__box-title

  &__box-title {
    color: #FFF;
    font-size: 2.5rem;
    font-weight: 700;
    padding: 31px;
    max-width: 480px;
  }

  // .basic-sections-content__sub-boxes

  &__sub-boxes {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
  }

  // .basic-sections-content__sub-box

  &__sub-box {
    border-radius: 10px;
    background: #0F3690;
    text-align: center;
    padding-top: 25px;
    padding-bottom: 25px;
  }

  &__sub-box {
    text-decoration: none;
  }

  &__sub-box:hover {
    text-decoration: none;
  }

  &__sub-box:nth-child(2) {
    background: #092C80;
  }

  &__sub-box:nth-child(3) {
    background: #0A38A6;
  }

  &__sub-boxes.right-boxes &__sub-box {
    border-radius: 10px;
    background: #0A38A6;
  }

  &__sub-boxes.right-boxes &__sub-box:nth-child(2) {
    background: #0F3690;
  }

  &__sub-boxes.right-boxes &__sub-box:nth-child(3) {
    background: #092C80;
  }

  // .basic-sections-content__sub-box-svg

  &__sub-box-svg {
    margin-bottom: 12px;
  }

  // .basic-sections-content__sub-box-title

  &__sub-box-title {
    color: #FFF;
    font-size: 1.563rem;
    font-weight: 700;
    width: min-content;
    margin: 0 auto;
  }
}

.main-sections-content {

  // .main-sections-content__sub-box-svg

  &__sub-box-svg {
  }
}

@media (max-width: 1400px) {
  .basic-sections-content__boxes {
    grid-template-columns: repeat(1, 1fr);
  }

  .basic-sections-content__box {
    background-size: cover !important;
  }

  .basic-sections-content__box.left {
    order: 1;
  }

  .basic-sections-content__box.right {
    order: 3;
  }

  .basic-sections-content__box {
  }

  .basic-sections-content__sub-boxes.left-boxes {
    order: 2;
  }
  .basic-sections-content__sub-boxes.right-boxes {
    order: 4;
  }
}

@media (max-width: 768px) {
  .basic-sections-content__sub-boxes {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 400px) {
  .basic-sections-content__box-title {
    padding: 19px;
  }
}


//statistic
.statistic {
  background: url("/img/statistic.webp") no-repeat, lightgray 50% / cover no-repeat;
  background-size: cover;
  padding-top: 40px;
  padding-bottom: 30px;
}

.statistic-wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
}

.statistic-left {
  color: #FFF;
  font-weight: 700;
  width: 50%;
  // .statistic-left__title

  &__title {
    font-size: 3.75rem;
    margin-bottom: 22px;
    max-width: 592px;
  }

  // .statistic-left__date

  &__date {
    font-size: 3.125rem;
    font-weight: normal;
    font-style: italic;
  }
}

.statistic-right {
  color: #FFF;
  font-weight: 700;
  width: 50%;

  // .statistic-right__text

  &__text {
    font-size: 1.875rem;
    margin-bottom: 6px;
    max-width: 80%;
  }

  // .statistic-right__number

  &__number {
    font-size: 5rem;
    font-style: italic;
  }
}

.statistic-right-block {
  margin-bottom: 22px;
}

@media (max-width: 992px) {
  .statistic-wrapper {
    flex-direction: column;
    align-items: start;
  }

  .statistic-left {
    width: 100%;
  }

  .statistic-right {
    width: 100%;
  }

  .statistic-left .statistic-left__title,
  .statistic-left .statistic-left__date {
    max-width: 100%;
  }

  .statistic-right__text {
    max-width: 100%;
  }

  .statistic-left {
    margin-bottom: 64px;
  }
}

@media (max-width: 576px) {
  .statistic-left .statistic-left__title,
  .statistic-left .statistic-left__date {
    font-size: 2.5rem !important;
    //max-width: 100%;
  }

  .statistic-right__text {
    //max-width: 100%;
  }
}

//faq
.faq {
  background: url("/img/faq.webp") no-repeat, lightgray 50% / cover no-repeat;
  background-size: cover;
  padding-top: 100px;
  padding-bottom: 40px;
}

.faq-wrapper {
  display: grid;
  grid-template-columns: .7fr 1fr;
  grid-gap: 20px;
}

.faq-title {
  max-width: 479px;
}

.faq-title .main-title {
  color: #FFF !important;
  font-size: 3.75rem;
  font-weight: 700;
}

.faq-title .line-title {
  background: #fff;
  height: 5px;
}

.faq-content {
}

.faq-content-title {
  color: #FFF;
  font-size: 2.25rem;
  margin-bottom: 22px;
}

.faq-content-text {
  color: #FFF;
  font-size: 1.25rem;
  margin-bottom: 22px;
}

.faq-content-link {
  color: #fff;
  font-size: 1.5rem;
  font-weight: 700;
}

.faq-content-link:hover {
  color: #fff !important;
}

@media (max-width: 1200px) {
  .faq-wrapper {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 23px;
  }
}

@media (max-width: 768px) {
  .faq-content-title {
    font-size: 2.25rem;
  }
  .faq-title .main-title {
    font-size: 2.5rem;
  }
}

//check
.check {
  padding: 50px 0;
}

.check-wrapper {
  border-radius: 10px;
  background: #0F3690;
}

.check-text-content {
  display: flex;
  align-items: center;
  padding: 36px 130px;
}

.check-title {
  color: #FFF;
  font-size: 3.75rem;
  font-weight: 700;
}

.check .main-button {
  color: #0F3690;
  border: 3px solid #0F3690;
  background: #fff;
  outline: 4px solid #fff;
}

@media (max-width: 1200px) {
  .check-text-content {
    flex-direction: column;
    text-align: center;
  }

  .check-title {
    margin-bottom: 46px;
  }
}

@media (max-width: 992px) {
  .check-title {
    font-size: 2.5rem;
  }
}

//fast-links
.fast-links {
  padding: 50px 0 100px;
  background: #F9F9F9;
}

.fast-links-wrapper {
}

.fast-links-content {
  margin-top: 50px;
}

.fast-links-title .main-title {
  color: #000;
}

.fast-links-title .line-title {
  background: #000;
  height: 5px;
}

.fast-links-content-boxes {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 20px;
}

.fast-links-content-box {
  border-radius: 10px;
  border: 1px solid #d9d9d9;
  background: #fff;
  padding: 17px 6px 9px;
  text-align: center;
  text-decoration: none;
  color: #111;
}

.fast-links-content-box-img {
  height: 80px;
}

.fast-links-content-box-text {
  color: #111;
  text-align: center;
  font-size: 1rem;
}

@media (max-width: 1400px) {
  .fast-links-content-boxes {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 992px) {
  .fast-links-content-boxes {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .fast-links-content-boxes {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 576px) {
  .fast-links-content-boxes {
    grid-template-columns: repeat(1, 1fr);
  }
}

//office
.office {
  padding: 50px 0 100px;
}

.office-wrapper {
}

.office-title {
  .main-title {
    color: #000;
  }

  .line-title {
    background: #000;
    height: 5px;
  }
}

.office-map {
  width: 100%;
  height: 500px;
  margin-top: 50px;
}

.office-map iframe {
  width: 100%;
  height: 500px;
}

.office-map-link {
  color: #000;
  font-size: 1.125rem;
  font-weight: 700;
  text-align: center;
  display: block;
  margin-top: 28px;
  margin-bottom: 20px;
}

.office-map-link:hover {
  color: #000 !important;
}

@media (max-width: 576px) {
  .office {
    margin-bottom: 30px;
  }
}
