@charset "UTF-8";
@font-face {
  font-weight: 400; /* Соответствует значению normal */
  font-family: Inter;
  src: url("../font/Inter-Regular.woff");
}
@font-face {
  font-weight: 500; /* Соответствует значению normal */
  font-family: Inter;
  src: url("../font/Inter-Medium.woff");
}
@font-face {
  font-weight: 600; /* Соответствует значению normal */
  font-family: Inter;
  src: url("../font/Inter-SemiBold.woff");
}
@font-face {
  font-weight: 700; /* Соответствует значению normal */
  font-family: Inter;
  src: url("../font/Inter-Bold.woff");
}
@font-face {
  font-weight: 800; /* Соответствует значению normal */
  font-family: Inter;
  src: url("../font/Inter-ExtraBold.woff");
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden], template {
  display: none;
}

html {
  background: #fff;
  color: #000;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

html,
button,
input,
select,
textarea {
  font-family: sans-serif;
}

body {
  margin: 0;
}

a {
  background: transparent;
}
a:focus {
  outline: thin dotted;
}
a:hover, a:active {
  outline: 0;
}

body p {
  margin: 0;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

h2 {
  font-size: 1.5em;
  margin: 0.83em 0;
}

h3 {
  font-size: 1.17em;
  margin: 1em 0;
}

h4 {
  font-size: 1em;
  margin: 1.33em 0;
}

h5 {
  font-size: 0.83em;
  margin: 1.67em 0;
}

h6 {
  font-size: 0.75em;
  margin: 2.33em 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

mark {
  background: #ff0;
  color: #000;
}

code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  font-size: 1em;
}

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

q {
  quotes: "“" "”" "‘" "’";
}

q:before, q:after {
  content: "";
  content: none;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 0;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  padding: 0;
  white-space: normal;
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
}

button, input {
  line-height: normal;
}

button,
select {
  text-transform: none;
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
input[disabled] {
  cursor: default;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input[type=search] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}
input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

body {
  color: #fff;
  font-size: 1.125rem;
  font-family: "Inter", sans-serif;
  font-style: normal !important;
  line-height: normal !important;
  font-weight: 400;
}

input {
  font-size: 0.75rem;
  line-height: normal;
}

.my-container {
  max-width: 1512px;
  padding: 0 46px;
  margin: 0 auto;
}

a::after {
  display: none !important;
}

a:hover {
  color: inherit !important;
}

@media (max-width: 860px) {
  .my-container {
    padding: 0 14px;
  }
}
.header {
  color: #fff;
}
.header__top {
  background: #021F7F;
  padding: 11px 0 15px 0;
}
.header__top .my-container {
  display: flex;
  justify-content: space-between;
}
.header__top-text {
  max-width: 510px;
  font-size: 1.125rem;
  font-weight: 700;
}
.header__top-block-phone {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 1.75rem;
  font-weight: 500;
}
.header__top-phone-text {
  margin-right: 7px;
}
.header__bottom {
  padding: 22px 0 51px 0;
  background: var(--Main-blue, #06297B);
}
.header__bottom .my-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header__bottom-content {
  font-size: 1rem;
}
.header__bottom-content a.header-contact {
  font-weight: 700;
}
.header__bottom-content a.header-contact:hover {
  color: #98b3ff !important;
}
.header__bottom-content .header__bottom-content-elem-lang button {
  font-size: 1.125rem;
}
.header__bottom-content .navbar-nav.header-navbar-nav-items {
  justify-content: end;
  margin-right: -19px;
  margin-left: 15px;
}
.header__bottom-content .navbar-nav.header-navbar-nav-items button {
  background: none;
  padding: 0;
  border: none;
  color: #fff;
  font-weight: 700;
}
.header__bottom-content .navbar-nav.header-navbar-nav-items button:hover {
  color: #98b3ff;
}
.header__bottom-content .navbar-nav.header-navbar-nav-items button:after {
  display: none;
}
.header__bottom-content-elems {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: end;
}
.header__bottom-content-elem-search {
  height: 30px;
}
.header__bottom-content-elem-eye {
  margin-left: 20px;
  margin-right: 26px;
}
.header__bottom-content-elem-lang-ky, .header__bottom-content-elem-lang-ru {
  background: none;
  border: none;
  padding: 0;
  color: #CACACA;
}
.header__bottom-content-elem-lang-ky {
  margin-right: 12px;
}
.header__bottom-content-elem-lang-ky.active, .header__bottom-content-elem-lang-ru.active {
  font-weight: 700;
  color: #FFF;
}
.header__bottom-mobile-content {
  display: none;
}

.block-info-img {
  display: flex;
}

.navbar-nav.header-navbar-nav-items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.navbar-nav.header-navbar-nav-items a {
  padding: 0;
  text-decoration: none;
  color: #fff;
}

.nav-item.dropdown.header-nav-item ul.dropdown-menu.show {
  border: none;
  inset: 7px auto auto 0 !important;
  width: 280px;
}

.nav-item.dropdown.header-nav-item ul.dropdown-menu.show a {
  white-space: inherit;
}

.navbar-nav.header-navbar-nav-items ul.dropdown-menu.show {
  border-radius: inherit;
  margin: 0 !important;
  background: #06297B;
  padding-left: 45px;
  list-style-type: disc !important;
  padding-right: 24px;
  color: #fff !important;
}

.navbar-nav.header-navbar-nav-items .dropdown-item {
  margin-bottom: 11px;
}

.navbar-nav.header-navbar-nav-items .dropdown-item:hover {
  background: none;
}

.navbar-nav.header-navbar-nav-items li {
  margin-right: 19px;
  margin-bottom: 7px;
}

.header-nav-item-sub-nav {
  font-weight: 700;
  margin-bottom: 5px;
}

.element-input {
  border: 1px solid #FFF;
  border-radius: 10px;
  padding: 7px 10px;
  width: 285px;
  max-width: 285px;
  background: none;
  color: #fff;
}

.element-input:focus-visible {
  outline: none;
}

.header__bottom-content-elem-search {
  position: relative;
}

.element-input::placeholder {
  font-weight: 700;
  font-style: italic;
  font-size: 0.75rem;
  color: #fff;
}

.header__bottom-content-elem-search svg {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  right: 18px;
}

@media (max-width: 1250px) {
  .header__top-phone-text {
    white-space: nowrap;
  }
  .navbar-nav.header-navbar-nav-items {
    justify-content: flex-end;
    margin-right: -19px;
  }
  .navbar-nav.header-navbar-nav-items li {
    margin-right: 19px;
    margin-bottom: 10px;
  }
}
@media (max-width: 900px) {
  .header__bottom-content {
    display: none;
  }
  .header__bottom-mobile-content {
    display: block;
  }
  .footer-info {
    grid-template-columns: repeat(2, 1fr);
  }
  .footer-info__block-img {
    margin-bottom: 32px;
  }
  .footer-info__block-address-text {
    max-width: 350px;
  }
  .navbar-nav.header-navbar-nav-items li {
    margin-right: 19px;
  }
  .header__top .my-container {
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
  .header__top-text {
    font-size: 0.875rem;
    margin-bottom: 11px;
  }
}
@media (max-width: 400px) {
  .header__bottom-mobile-content .menu .header__bottom-content-elem-search {
    margin-right: auto;
  }
  .header__bottom-mobile-content .menu .header__bottom-content-elem-search input {
    width: 100%;
  }
  .header__bottom-mobile-content .menu .header__bottom-content-elem-eye {
    margin: 0 10px;
  }
  .header__bottom-mobile-content .menu .header__bottom-content-elem-lang-ky {
    margin-right: 5px;
  }
}
.nav input {
  display: none;
}
.nav label {
  cursor: pointer;
}

.nav {
  user-select: none;
}

.nav__showmenu {
  position: relative;
  z-index: 3;
  display: inline-block;
  margin-left: -10px;
  padding: 0;
  top: 2px;
  font-size: 40px;
}

.nav__back {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 0.3s;
}

.menu, .menu__submenu {
  list-style: none;
  padding: 0;
  color: #fff;
  overflow: hidden;
}

.menu {
  position: absolute;
  top: 225px;
  left: 0;
  display: block;
  width: 100%;
  margin: 0;
  padding: 17px 0;
  text-transform: uppercase;
  line-height: 2em;
  background: #06297B;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  transform: translateX(-100%);
  transition: transform 0.3s;
  will-change: transform;
  backface-visibility: hidden;
  z-index: 10;
}

.menu__submenu {
  font-size: 0.8em;
  background-color: #ddd;
  max-height: 0;
  transition: max-height 0.3s ease-in-out;
}

.menu__showsub {
  position: absolute;
  right: 0;
  margin-top: -34px;
  padding: 10px;
  line-height: normal;
}

.menu__item {
  display: block;
  text-align: center;
  text-decoration: none;
  color: #fff;
}

.menu__submenu .menu__item {
  color: #000;
}

.nav__check:checked ~ .nav__back {
  background-color: rgba(0, 0, 0, 0.2);
  bottom: 0;
}

.menu__item:hover, .nav__showmenu:hover, .menu__showsub:hover {
  opacity: 0.7;
}

.menu::-webkit-scrollbar {
  display: none;
}

.nav__check:checked + .nav__showmenu {
  color: #fff;
}

.menu__check:checked ~ .menu__submenu {
  max-height: 100vh;
}

.menu__check:checked + .menu__showsub {
  transform: rotate(180deg);
}

.nav__check:checked ~ .menu {
  transform: translateX(0);
}

.menu button {
  width: 100%;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  border: none;
}
.menu button:after {
  content: inherit;
}
.menu .dropdown-menu.show {
  position: static !important;
  transform: inherit !important;
  border: none;
  background: inherit;
}
.menu .dropdown-item {
  background: inherit;
  color: #fff;
}

.element-input-box-mobile {
  display: none;
}

.header__bottom-content-elem-search input {
  display: block;
}

.menu .my-container {
  flex-direction: column;
  justify-content: inherit;
  align-items: inherit;
}
.menu .my-container .header__bottom-content-elem-eye {
  margin-left: 19px;
  margin-right: 14px;
}
.menu .my-container .header__bottom-content-elem-lang-ky, .menu .my-container .header__bottom-content-elem-lang-ru {
  background: none;
  border: none;
  padding: 0;
  color: #CACACA;
}
.menu .my-container .header__bottom-content-elem-lang-ky.active, .menu .my-container .header__bottom-content-elem-lang-ru.active {
  font-weight: 700;
  color: #FFF;
}
.menu .my-container .menu-burger-nav {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}
.menu .my-container .menu-burger-nav .header-contact.header-contact-mobile {
  color: #fff;
  text-decoration: none;
}
.menu .my-container .menu-burger-nav .header-contact.header-contact-mobile:hover {
  color: #98b3ff;
}
.menu .my-container .menu-burger-nav .nav-item.dropdown.menu-main-link button {
  text-align: start;
  padding: 0;
  font-size: 1.375rem;
}
.menu .my-container .menu-burger-nav .nav-item.dropdown.menu-main-link ul {
  padding-left: 25px;
  list-style: disc;
  color: #fff;
}
.menu .my-container .menu-burger-nav .nav-item.dropdown.menu-main-link:not(:last-child) {
  margin-bottom: 6px;
}
.menu .my-container .menu-burger-nav .nav-item.dropdown.menu-main-link .btn.dropdown-toggle.show svg {
  transform: rotate(-180deg);
}
.menu .my-container .menu-burger-nav .nav-item.dropdown.menu-main-link button:focus {
  box-shadow: none;
}
.menu .my-container .menu-burger-nav .nav-item.dropdown.menu-main-link button:hover {
  color: #98b3ff;
}
.menu .my-container .menu-burger-nav .nav-item.dropdown.menu-main-link button svg {
  margin-left: 13px;
}
.menu .my-container .menu-burger-nav .dropdown-item {
  padding: 0;
  font-size: 1.25rem;
  text-transform: capitalize;
}
.menu .my-container .menu-burger-nav .dropdown-item:hover {
  color: #98b3ff !important;
}
.menu .my-container .menu-burger-nav .header-nav-item-sub-nav {
  font-size: 1.25rem;
  color: #fff;
}
.menu .my-container .header__bottom-content-elem-lang {
  display: flex;
}
.menu .my-container .element-input {
  padding: 9px 10px;
  height: 30px;
  width: 207px;
}

#eyeStaticBackdrop, .menu__submenu .menu__item {
  color: #000;
}

#eyeStaticBackdrop .modal-content {
  padding: 20px 20px 0;
  border-radius: 10px;
}

#eyeStaticBackdrop .modal-footer {
  border: 0;
  padding: 0 0 20px;
}

#eyeStaticBackdrop .modal-footer button {
  margin: 0;
}

#eyeStaticBackdrop .modal-dialog {
  max-width: max-content;
}

#eyeStaticBackdrop .modal-footer {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

#eyeStaticBackdrop .eye-color, #eyeStaticBackdrop .eye-font {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

#eyeStaticBackdrop .eye-color .eye-color-text, #eyeStaticBackdrop .eye-color .eye-font-text, #eyeStaticBackdrop .eye-font .eye-color-text, #eyeStaticBackdrop .eye-font .eye-font-text {
  margin: 0 40px 0 0;
}

#eyeStaticBackdrop .eye-color .eye-color-btns, #eyeStaticBackdrop .eye-color .eye-font-btns, #eyeStaticBackdrop .eye-font .eye-color-btns, #eyeStaticBackdrop .eye-font .eye-font-btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

#eyeStaticBackdrop .eye-color-btn, #eyeStaticBackdrop .eye-font-btn {
  background: inherit;
  border: 1px solid #1d4d7b;
}

#eyeStaticBackdrop .eye-font-btn {
  padding: 5px 10px;
}

#eyeStaticBackdrop .eye-color-btn {
  padding: 10px 15px;
}

#eyeStaticBackdrop .eye-color-btn:not(:last-child) {
  margin-right: 20px;
}

#eyeStaticBackdrop .eye-font-btn:hover {
  background: #033a70;
  color: #fff;
}

#eyeStaticBackdrop .eye-font-btn:nth-child(1) {
  font-size: 1rem;
  margin-right: 10px;
}

#eyeStaticBackdrop .eye-font-btn:nth-child(2) {
  font-size: 1.25rem;
  margin-right: 10px;
}

#eyeStaticBackdrop .eye-font-btn:nth-child(3) {
  font-size: 1.5rem;
}

#eyeStaticBackdrop .eye-color-btn:nth-child(2) {
  background: #000;
  color: #fff;
}

#eyeStaticBackdrop .eye-color-btn:nth-child(3) {
  background: #033a70;
  color: #fff;
}

#eyeStaticBackdrop .eye-btn-close, #eyeStaticBackdrop .eye-btn-reset {
  background: #1d4d7b;
  color: #fff;
}

@media (max-width: 768px) {
  #eyeStaticBackdrop .modal-footer {
    flex-direction: column;
  }
  #eyeStaticBackdrop .eye-color, #eyeStaticBackdrop .eye-font {
    flex-direction: column;
    justify-content: center;
  }
  #eyeStaticBackdrop .eye-font-text,
  #eyeStaticBackdrop .eye-color-text {
    margin: 0 0 20px 0 !important;
  }
  #eyeStaticBackdrop .modal-footer button:not(:last-child) {
    margin-bottom: 10px;
  }
  #eyeStaticBackdrop .modal-dialog {
    max-width: 100%;
    padding: 0 10px;
  }
}
@media (max-width: 430px) {
  #eyeStaticBackdrop .eye-font-btns-size {
    flex-direction: column;
  }
  #eyeStaticBackdrop .eye-font-btns-size .eye-font-btn {
    margin: 0 0 10px 0;
  }
}
.btn-up {
  position: fixed;
  right: 20px;
  bottom: 20px;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  z-index: 99999;
}

.btn-up svg {
  border-radius: 50%;
}

.btn-up_hide {
  display: none;
}

.path5 {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
  }
  50% {
    transform: scale(1);
    opacity: 0.7;
  }
  100% {
    transform: scale(0.95);
  }
}
.path1 {
  opacity: 0;
  animation: wifiAnimation 3s infinite;
}

.path2 {
  opacity: 0;
  animation: wifiAnimation 3s infinite;
}

.path3 {
  opacity: 0;
  animation: wifiAnimation 3s infinite;
  animation-delay: 400ms;
}

.path4 {
  opacity: 0;
  animation: wifiAnimation 3s infinite;
  animation-delay: 800ms;
}

@-o-keyframes wifiAnimation {
  0% {
    opacity: 0.4;
  }
  15% {
    opactiy: 1;
  }
  30% {
    opactiy: 0.1;
  }
  100% {
    opactiy: 0.1;
  }
}
@-moz-keyframes wifiAnimation {
  0% {
    opacity: 0.4;
  }
  15% {
    opactiy: 1;
  }
  30% {
    opactiy: 0.1;
  }
  100% {
    opactiy: 0.1;
  }
}
@-webkit-keyframes wifiAnimation {
  0% {
    opacity: 0.4;
  }
  15% {
    opactiy: 1;
  }
  30% {
    opactiy: 0.1;
  }
  100% {
    opactiy: 0.1;
  }
}
.section-footer {
  background: url("/img/footer-bg.png") 0 no-repeat, #06297B;
  padding: 57px 0 42px 0;
  color: #fff;
}

.section-footer a {
  text-decoration: none;
  color: #fff;
  font-weight: 700;
}

.footer-info {
  display: grid;
  grid-template-columns: 1.2fr 1.2fr 1fr 0.5fr;
  margin-bottom: 97px;
  grid-gap: 10px;
}
.footer-info__block-img {
  display: flex;
}
.footer-info__text-img {
  margin-left: 9px;
  max-width: 114px;
  font-weight: 700;
}
.footer-info__block-address {
  font-weight: 700;
  font-size: 1.125rem;
}
.footer-info__block-address-text {
  margin-bottom: 9px;
}
.footer-info__block-content {
  display: flex;
  flex-direction: column;
}
.footer-info__block-content a:not(:last-child) {
  margin-bottom: 11px;
}
.footer-info__block-content-phone {
  text-decoration: underline !important;
}
.footer-info__block-social {
  font-size: 1.125rem;
}
.footer-info__block-social-text {
  margin-bottom: 13px;
  font-weight: 700;
}
.footer-info__block-social-icon a:not(:last-child) {
  margin-right: 10px;
}
.info-img-first {
  margin-right: 15px;
}

.info-img-second {
  margin-right: 18px;
}

.info-img-third {
  display: flex;
  align-items: start;
}

.footer-sites {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 82px;
  grid-gap: 10px;
}
.footer-sites__right {
  display: flex;
}
.footer-sites__right-img {
  margin-right: 18px;
}
.footer-sites__right-text {
  max-width: 434px;
  font-size: 0.875rem;
}
.footer-sites__left {
  display: flex;
}
.footer-sites__left-img {
  margin-right: 10px;
}
.footer-sites__left-text {
  font-weight: 400;
  max-width: 434px;
  font-size: 0.875rem;
}

.footer-copyright {
  border-top: 2px solid #fff;
  padding-top: 14px;
  font-size: 0.875rem;
}

@media (max-width: 1300px) {
  .footer-info {
    grid-template-columns: repeat(2, 1fr);
  }
  .footer-info__block-img {
    margin-bottom: 32px;
  }
  .footer-info__block-address-text {
    max-width: 350px;
  }
}
@media (max-width: 850px) {
  .footer-info {
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 61px;
  }
  .block-info-img {
    display: flex;
  }
  .footer-info__block-img {
    margin-bottom: 32px;
  }
  .footer-info__block-address-text {
    max-width: 350px;
  }
  .footer-info__block-address {
    margin-bottom: 32px;
  }
  .footer-info__block-content {
    margin-bottom: 32px;
    font-size: 1.125rem;
  }
  .footer-sites {
    flex-direction: column;
  }
  .footer-sites__right {
    margin-bottom: 26px;
  }
  .footer-sites__right,
  .footer-sites__left {
    flex-direction: column;
  }
  .footer-sites__right img,
  .footer-sites__left img {
    margin-bottom: 17px;
  }
}
@media (max-width: 400px) {
  .info-img-first {
    margin-right: 7px;
  }
  .info-img-second {
    margin-right: 7px;
  }
  .footer-info__text-img {
    font-size: 0.875rem;
  }
  .footer-info__text-img {
    margin-left: 5px;
  }
}
.law {
  padding: 46px 0 90px 0;
  background: url("/img/content-bg.png") 100% 0 no-repeat;
}

.title {
  width: 878px;
}

.main-title {
  color: #06297B;
  font-size: 3.125rem;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 19px;
  margin-top: 0;
}

.line-title {
  width: 120px;
  height: 10px;
  background: #06297B;
}

.law-content {
  color: #000;
  font-size: 1.25rem;
  line-height: normal;
  margin-top: 46px;
}

@media (max-width: 1300px) {
  .title {
    width: 100%;
  }
}
@media (max-width: 992px) {
  .main-title {
    font-size: 1.875rem;
  }
}
.service {
  padding: 46px 0 140px 0;
  background: url("/img/content-bg.png") 100% 46px no-repeat;
}

.service-content {
  margin-top: 98px;
}

.service-content-title {
  color: #000;
  text-align: center;
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 43px;
}

.service-content-box-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  margin-bottom: 43.5px;
}

.service-content-box-wrapper-bottom {
  display: flex;
  margin-bottom: 90px;
  grid-gap: 20px;
  justify-content: center;
}

.service-content-box {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 4px 0 15px 0 rgba(0, 0, 0, 0.25);
  padding: 37px 40px;
  text-align: center;
  color: #06297B;
  font-size: 1.25rem;
  font-weight: 700;
}

.service-content-box-bottom {
  width: 340px;
}

.service-content-box--blue {
  background: #021B54;
  width: 340px;
  color: #fff;
  padding: 27px 40px;
  margin: 0 auto 56px auto;
  font-size: 1.563rem;
  font-weight: 700;
}

.service-sub-content-sub-box-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.service-sub-content-sub-box {
  text-align: center;
  padding: 25px 30px;
  color: #FFF;
  font-size: 1.25rem;
  font-weight: 700;
  border-radius: 10px;
}

.service-sub-content-sub-box.blue-one {
  background: #082F89;
}

.service-sub-content-sub-box.blue-second {
  background: #082F89;
}

.service-sub-content-sub-box.blue-third {
  background: #08266D;
}

.service-sub-content__title {
  color: #000;
  margin: 0 auto 37.5px auto;
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 394px;
  text-align: center;
}

@media (max-width: 1250px) {
  .service-content-box-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 992px) {
  .service-content-box-wrapper-bottom {
    flex-direction: column;
  }
  .service-content-box-bottom {
    width: 100%;
  }
  .service-sub-content-sub-box-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media (max-width: 676px) {
  .service-content-box-wrapper {
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 20px;
  }
  .service-content-title {
    margin-bottom: 35px;
    width: 100%;
    text-align: start;
  }
  .service-sub-content__title {
    margin-bottom: 35px;
    width: 100%;
    text-align: start;
  }
}
@media (max-width: 400px) {
  .service-content-box.service-content-box--blue {
    width: 100%;
  }
}
.vacancies {
  padding: 46px 0 94px 0;
  background: url("/img/content-bg.png") 100% 46px no-repeat;
}

.box-cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 31px 18px;
  color: #000;
  font-size: 1.25rem;
  margin-top: 70px;
}

.box-card {
  padding: 28px 13px 16px 13px;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 4px 0 15px 0 rgba(0, 0, 0, 0.25);
}

.card-main-title {
  font-size: 1.875rem;
  font-weight: 700;
  margin-bottom: 13px;
}

.card-city {
  margin-bottom: 42px;
}

.card-main-link {
  color: #000;
}

@media (max-width: 1200px) {
  .box-cards {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 576px) {
  .box-cards {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 21px;
  }
}
.purchase {
  padding: 46px 0 133px 0;
  background: url("/img/content-bg.png") 100% 46px no-repeat;
}

.card-main-title-purchase {
  margin-bottom: 73px;
}

.partners {
  padding: 46px 0 95px 0;
  background: url("/img/content-bg.png") 100% 46px no-repeat;
}

.division-cards {
  margin-top: 63px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 20px;
  color: #111;
  font-size: 1rem;
}

.division-card {
  border-radius: 10px;
  border: 1px solid #D9D9D9;
  background: #FFF;
  padding: 17px 6px 9px;
  text-align: center;
  text-decoration: none;
  color: #111;
}

.division-card-img {
  height: 80px;
}

@media (max-width: 1356px) {
  .division-cards {
    grid-template-columns: repeat(5, 1fr);
  }
}
@media (max-width: 1176px) {
  .division-cards {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (max-width: 992px) {
  .division-cards {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 767px) {
  .division-cards {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 568px) {
  .division-cards {
    grid-template-columns: repeat(1, 1fr);
  }
}
.about {
  padding: 46px 0 100px;
  background: url("/img/content-bg.png") 100% 6px no-repeat;
}

.about-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 26px;
  margin-top: 55px;
}
.about-content__text {
  color: #000;
  font-size: 1.25rem;
}
.about-content__text p {
  color: #000;
  margin-bottom: 20px;
}
.about-content__text a {
  color: #000;
  text-decoration: none;
}
@media (max-width: 1300px) {
  .about-content {
    grid-template-columns: repeat(1, 1fr);
  }
  .about-content__left {
    order: 2;
  }
  .about-content__right {
    text-align: center;
  }
  .about-content__right img {
    max-width: 100%;
  }
}
.standard-base {
  padding: 46px 0 66px;
  background: url("/img/content-bg.png") 100% 46px no-repeat;
}

.standard-base-wrapper-content {
  margin-top: 77px;
}

.standard-base-wrapper-content-box-link {
  font-size: 1.125rem;
  text-decoration: none;
  color: #000;
  display: flex;
  margin-bottom: 26px;
}

.standard-base-wrapper-content-box-link:hover {
  color: #000 !important;
}

.standard-base-wrapper-content-box-link svg {
  margin-right: 21px;
}

.history {
  padding: 46px 0 170px 0;
  background: url("/img/content-bg.png") 100% 46px no-repeat;
}

.history-wrapper-content {
  color: #000;
  font-size: 1.25rem;
  margin-top: 84px;
}

.history-wrapper-content .history-wrapper-content-box:not(:last-child) {
  margin-bottom: 44px;
}

.history-wrapper-content-box {
  display: flex;
}

.history-wrapper-content-date {
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  background: #F3F0F0;
  padding: 8px 10px;
  font-size: 0.875rem;
  margin-right: 19px;
}

.history-wrapper-content-date-number {
  font-size: 1.625rem;
  font-weight: 700;
}

.history-wrapper-content-text-box {
  max-width: 85%;
}

@media (max-width: 576px) {
  .history-wrapper-content-box {
    flex-direction: column;
  }
  .history-wrapper-content-date {
    font-size: 1.875rem;
    flex-direction: row;
    justify-content: center;
    margin-right: 0;
    margin-bottom: 16px;
  }
  .history-wrapper-content-date-number {
    margin-right: 10px;
  }
  .history-wrapper-content-date-month {
    margin-right: 10px;
  }
  .history-wrapper-content-text-box {
    width: 100%;
  }
}
.symbolic {
  padding: 46px 0 95px 0;
  background: url("/img/content-bg.png") 100% 46px no-repeat;
  font-size: 1.25rem;
  color: #000;
}
.symbolic__content {
  margin-top: 52px;
}
.symbolic__content-text {
  margin-bottom: 50px;
}
.symbolic__content-text a {
  font-size: 1.25rem;
  color: #000;
  text-decoration: none;
}
.symbolic__content-images {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  grid-gap: 20px;
}
.symbolic__content-box-img {
  border-radius: 10px;
}
.symbolic__content-img {
  border-radius: 10px;
}

@media (max-width: 576px) {
  .symbolic__content-box-img {
    width: 100%;
  }
  .symbolic__content-img {
    width: 100%;
  }
}
.vacancy {
  padding: 46px 0 95px 0;
  background: url("/img/content-bg.png") 100% 46px no-repeat;
}

.one-content {
  color: #000;
  font-size: 1.25rem;
  margin-top: 60px;
}

.one-content-text a {
  color: #000;
  font-size: 1.25rem;
  text-decoration: none;
}

.one-purchase {
  padding: 46px 0 95px 0;
  background: url("/img/content-bg.png") 100% 46px no-repeat;
}

.hotline {
  padding: 46px 0 144px 0;
  background: url("/img/content-bg.png") 100% 46px no-repeat;
}

.hotline-content {
  color: #000;
  font-size: 1.25rem;
  margin-top: 40px;
}
.hotline-content__text {
  margin-bottom: 64px;
  max-width: 85%;
}
.hotline-content__text a {
  color: #000;
  text-decoration: none;
}

.main-button {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 1.75rem;
  font-weight: 500;
  padding: 12px 70px;
  border-radius: 10px;
  border: 2px solid #FFF;
  background: #06297B;
  outline: 4px solid #06297B;
}

.main-button svg {
  margin-left: 11px;
}

@media (max-width: 576px) {
  .main-button {
    padding: 10px 29px;
  }
}
.bus {
  padding: 46px 0 92px 0;
  background: url("/img/content-bg.png") 100% 46px no-repeat;
}

.bus-content {
  margin-top: 54px;
  margin-bottom: 70px;
  color: #000;
  font-size: 1.25rem;
  overflow: hidden !important;
}
.bus-content__text a {
  color: #000;
  text-decoration: none;
}
.bus-content__box-img {
  float: right;
  border-radius: 10px;
  margin-left: 40px;
}
.bus-content__img {
  border-radius: 10px;
}

.cards-video {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.card-video {
  color: #000;
  font-size: 1.125rem;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}

.box-video {
  width: 100%;
  border-radius: 10px 10px 0 0;
  height: 300px;
}

.box-video iframe {
  width: 100%;
  height: 300px;
  border-radius: 10px 10px 0 0;
}

.title-video {
  font-weight: 700;
  padding: 32px 18px 58px;
}

@media (max-width: 1200px) {
  .bus-content {
    display: flex;
    flex-direction: column;
    overflow: inherit;
  }
  .bus-content__box-text {
    margin-bottom: 50px;
  }
  .bus-content__box-img {
    margin: auto;
    float: inherit;
    order: 2;
  }
  .bus-content__img {
    width: 100%;
  }
  .cards-video {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 768px) {
  .cards-video {
    grid-template-columns: repeat(1, 1fr);
  }
}
.docs {
  padding: 46px 0 84px 0;
  background: url("/img/content-bg.png") 100% 46px no-repeat;
}

.docs-content {
  margin-top: 51px;
  font-size: 1.125rem;
}

.box-link {
  display: flex;
  align-items: center;
  color: #000;
  text-decoration: none;
  margin-bottom: 8px;
}

.box-link:hover {
  color: #000 !important;
  text-decoration: underline;
}

@media (max-width: 576px) {
  .box-link {
    align-items: start;
    margin-bottom: 20px;
  }
}
.acts {
  padding: 46px 0 84px 0;
  background: url("/img/content-bg.png") 100% 46px no-repeat;
}

.acts-content {
  margin-top: 51px;
  font-size: 1.125rem;
}

.announcements {
  padding: 46px 0 77px 0;
  background: url("/img/content-bg.png") 100% 46px no-repeat;
}

.announcements-content {
  color: #000;
  font-size: 1.25rem;
}

.sub-main-text {
  margin-top: 29px;
  margin-bottom: 56px;
  color: #000;
  font-size: 1.25rem;
  max-width: 75%;
}

.block-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 25px;
}

.block-card {
  width: 100%;
  color: #000;
  font-size: 1.125rem;
  font-weight: 700;
  text-decoration: none;
}

.block-card:hover {
  color: #000 !important;
}

.block-card-img {
  border-radius: 10px 10px 0 0;
}

.block-card-img img {
  border-radius: 10px 10px 0 0;
  width: 100%;
}

.block-card-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 10px 14px;
  border-radius: 0 0 10px 10px;
  border: 1px solid #ECECEC;
}

.block-card-content-date {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  background: #F3F0F0;
  font-size: 0.875rem;
  margin-right: 19px;
  padding: 10px;
}

.block-card-content-date-number {
  font-size: 1.625rem;
}

@media (max-width: 992px) {
  .block-cards {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 768px) {
  .block-cards {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 21px;
  }
  .sub-main-text {
    max-width: 100%;
  }
}
.one-announcement {
  padding: 11px 0 77px 0;
  background: url("/img/content-bg.png") 100% 46px no-repeat;
}

.back-page-btn {
  display: inline-block;
  color: #000;
  font-size: 1.25rem;
  font-style: italic;
  font-weight: 700;
  margin-bottom: 44px;
  text-decoration: none;
}

.back-page-btn:hover {
  color: #000 !important;
}

.title-data {
  display: inline-block;
  color: #000;
  font-size: 1.25rem;
  font-style: italic;
  font-weight: 700;
  margin-bottom: 11px;
}

.one-announcement-content {
  margin-top: 52px;
  margin-bottom: 40px;
  color: #000;
  font-size: 1.25rem;
}

.content-block-img {
  float: right;
  border-radius: 10px;
  margin-left: 20px;
  margin-bottom: 20px;
}

.content-img {
  border-radius: 10px;
}

.content-text p {
  margin-bottom: 25px;
}

.block-social {
  display: flex;
}

.block-social a:not(:last-child) {
  margin-right: 4px;
}

.block-social-link {
  display: block;
  text-decoration: none;
}

.block-social-link:last-child {
  background: #06297B;
}

@media (max-width: 1200px) {
  .one-announcement-content {
    display: flex;
    flex-direction: column;
  }
  .content-block-img {
    margin: 0 auto 40px auto;
    order: 2;
  }
  .content-img {
    width: 100%;
  }
}
.news {
  padding: 46px 0 107px 0;
  background: url("/img/content-bg.png") 100% 46px no-repeat;
}

.news-content {
  margin-top: 29px;
  color: #000;
  font-size: 1.25rem;
}

.one-news {
  padding: 11px 0 77px 0;
  background: url("/img/content-bg.png") 100% 46px no-repeat;
}

.one-news-content {
  margin-top: 52px;
  color: #000;
  font-size: 1.25rem;
}

@media (max-width: 1200px) {
  .one-news-content {
    display: flex;
    flex-direction: column;
  }
}
.video-materials {
  padding: 46px 0 147px 0;
  background: url("/img/content-bg.png") 100% 46px no-repeat;
}

.contacts-centre {
  padding: 46px 0 94px 0;
  background: url("/img/content-bg.png") 100% 46px no-repeat;
}

.contacts-centre-content {
  font-size: 1.5rem;
}

.contacts-centre-content .sub-main-text {
  margin-top: 19px;
  margin-bottom: 11px;
}

.main-phone {
  color: #000;
  font-size: 1.625rem;
  font-weight: 700;
}

.contacts-centre-content-info {
  margin-top: 53px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contacts-centre-content-info-text {
  color: #000;
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 51px;
  text-align: center;
}

.contacts-centre-content-info-boxes {
  display: flex;
  grid-gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
}

.contacts-centre-content-info-box {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  color: #FFF;
  text-align: center;
  font-size: 1.5rem;
  border-radius: 10px;
  background: #082F89;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  padding: 33px 50px;
  min-height: 200px;
  width: 338px;
}

.contacts-centre-content-info-box-title {
  font-weight: 700;
  margin-bottom: 7px;
}

.contacts-centre-content-info-box-text {
  font-weight: 400;
}

.contacts-centre-content-info-box-text p {
  margin-bottom: 11px;
}

.contacts-centre-content-info-sub-text {
  color: #000;
  text-align: center;
  font-size: 2.25rem;
  font-weight: 500;
}

@media (max-width: 576px) {
  .contacts-centre-content-info-boxes {
    width: 100%;
    justify-content: unset;
    flex-direction: column;
  }
  .contacts-centre-content-info-box {
    width: 100%;
  }
  .contacts-centre-content-info-text {
    max-width: 100%;
    word-break: break-word;
  }
}
.contact-territory {
  padding: 46px 0 98px 0;
  background: url("/img/content-bg.png") 100% 46px no-repeat;
}

.contact-territory-content {
  margin-bottom: 19px;
}

.contact-territory-content-boxes {
  margin-top: 43px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.contact-territory-content-box {
  border-radius: 10px;
  background: #0A38A6;
  padding: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-territory-content-box:nth-child(2n) {
  background: #082F89;
}

.contact-territory-content-box:nth-child(3n) {
  background: #08266D;
}

.contact-territory-content-text {
  color: #FFF;
  text-align: center;
  font-size: 1.563rem;
  font-weight: 700;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .contact-territory-content-boxes {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 768px) {
  .contact-territory-content-boxes {
    grid-template-columns: repeat(1, 1fr);
  }
}
.territory-all-regions {
  padding: 46px 0 147px 0;
  background: url("/img/content-bg.png") 100% 46px no-repeat;
}

.territory-all-regions-box-main {
  border-radius: 10px;
  background: #001560;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  padding: 22px 35px;
  max-width: 460px;
  margin: 0 auto 20px auto;
}

.territory-all-regions-box-main-title {
  color: #FFF;
  text-align: center;
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 10px;
}

.territory-all-regions-box-main-content {
  color: #FFF;
  text-align: center;
  font-size: 1.125rem;
  font-weight: 400;
}

.territory-all-regions-box-main-content p {
  color: #FFF;
  text-align: center;
  font-size: 1.125rem;
  font-weight: 400;
  margin-bottom: 10px;
}

.territory-all-regions-box-main-content a {
  color: #FFF;
  text-align: center;
  font-size: 1.125rem;
  font-weight: 400;
}

.territory-all-regions-boxes {
  display: flex;
  grid-gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
}

.territory-all-regions-box {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #0A38A6;
  width: 460px;
  height: 220px;
  text-decoration: none;
}

.territory-all-regions-box-text {
  color: #FFF;
  text-align: center;
  font-size: 1.563rem;
  font-weight: 700;
  padding: 20px;
}

.territory-all-regions-box:nth-child(2n) {
  background: #082F89;
}

.territory-all-regions-box:nth-child(3n) {
  background: #08266D;
}

.territory-all-regions-box:nth-child(4n) {
  background: #0A38A6;
}

.territory-all-regions-box:nth-child(5n) {
  background: #082F89;
}

.territory-all-regions-box:nth-child(6n) {
  background: #08266D;
}

.territory-all-regions-box:nth-child(7n) {
  background: #0A38A6;
}

.territory-all-regions-box:nth-child(8n) {
  background: #082F89;
}

.territory-all-regions-box:nth-child(9n) {
  background: #08266D;
}

.territory-region {
  padding: 46px 0 0 0;
  background: url("/img/content-bg.png") 100% 46px no-repeat;
}

.territory-region-content-boxes {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  margin-top: 41px;
  margin-bottom: 103px;
}

.territory-region-content-box {
  border-radius: 10px;
  border: 1px solid #E6E6E6;
  background: #0A38A6;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  padding: 31px 13px 28px;
  color: #FFF;
  text-align: center;
}

.territory-region-content-box:nth-child(2n) {
  background: #082F89;
}

.territory-region-content-box:nth-child(3n) {
  background: #08266D;
}

.territory-region-content-box:nth-child(4n) {
  background: #021B54;
}

.territory-region-content-box:nth-child(6n) {
  background: #082F89;
}

.territory-region-content-box:nth-child(7n) {
  background: #08266D;
}

.territory-region-content-box:nth-child(8n) {
  background: #021B54;
}

.territory-region-content-box-title {
  font-weight: 700;
  font-size: 1.5rem;
  margin-bottom: 12px;
}

.territory-region-content-box-title-line {
  width: 120px;
  height: 4px;
  background: #fff;
  margin: 12px auto 0 auto;
}

.territory-region-content-text {
  font-weight: 400;
  font-size: 1.125rem;
}

.territory-region-content-text p:not(:last-child) {
  margin-bottom: 43px;
}

.map {
  width: 100%;
  max-height: 500px;
}

.map iframe {
  width: 100%;
}

@media (max-width: 1400px) {
  .territory-region-content-boxes {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 992px) {
  .territory-region-content-boxes {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 768px) {
  .territory-region-content-boxes {
    grid-template-columns: repeat(1, 1fr);
  }
}
.coordinators {
  padding: 46px 0 131px 0;
  background: url("/img/content-bg.png") 100% 46px no-repeat;
}

.coordinators-content-boxes {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.coordinators-content-box {
  text-decoration: none;
  color: #FFF;
  font-size: 1.563rem;
  font-weight: 700;
  padding: 80px 0;
  border-radius: 10px;
  background: #0A38A6;
  text-align: center;
}

.coordinators-content-box-title {
  margin: 0 auto;
  max-width: 40%;
}

.coordinators-content-box:nth-child(2n) {
  background: #082F89;
}

.coordinators-content-box:nth-child(3n) {
  background: #08266D;
}

.coordinators-content-box:nth-child(4n) {
  background: #0A38A6;
}

.coordinators-content-box:nth-child(5n) {
  background: #082F89;
}

.coordinators-content-box:nth-child(6n) {
  background: #08266D;
}

.coordinators-content-box:nth-child(7n) {
  background: #0A38A6;
}

.coordinators-content-box:nth-child(8n) {
  background: #082F89;
}

.coordinators-content-box:nth-child(9n) {
  background: #08266D;
}

@media (max-width: 992px) {
  .coordinators-content-boxes {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 576px) {
  .coordinators-content-boxes {
    grid-template-columns: repeat(1, 1fr);
  }
}
.coordinator-region {
  padding: 46px 0 131px 0;
  background: url("/img/content-bg.png") 100% 46px no-repeat;
}

.coordinator-region-content-title {
  color: #000;
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 37px;
}

.coordinator-region-content-boxes {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.coordinator-region-content-box {
  text-align: center;
  border-radius: 10px;
  border: 1px solid #ECECEC;
  background: #FFF;
  box-shadow: 4px 4px 15px 0 rgba(0, 0, 0, 0.25);
}

.coordinator-region-content-box-img {
  border-radius: 150px;
  margin-bottom: 20px;
}

.coordinator-region-content-box-img img {
  border-radius: 150px;
}

.coordinator-region-content-box-title {
  color: #202020;
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 12px;
}

.coordinator-region-content-title-line {
  width: 120px;
  height: 4px;
  background: #202020;
  margin: 12px auto 0 auto;
}

.coordinator-region-content-box-text {
  color: #000;
  font-size: 1.125rem;
  font-weight: 400;
  margin-bottom: 25px;
}

.coordinator-region-content-box-address {
  color: #000;
  font-size: 1.125rem;
  font-weight: 400;
}

.coordinator-region-content-sub-box {
  padding: 0 57px 30px;
}

.coordinator-region-content-boxes-title {
  color: #FFF;
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  border-radius: 10px 10px 0 0;
  background: #0A38A6;
  padding: 35px;
  margin-bottom: 10px;
}

.coordinator-region-content-box-text p:not(:last-child) {
  margin-bottom: 25px;
}

@media (max-width: 1200px) {
  .coordinator-region-content-boxes {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 768px) {
  .coordinator-region-content-boxes {
    grid-template-columns: repeat(1, 1fr);
  }
}
.online {
  padding: 46px 0 92px 0;
  background: url("/img/content-bg.png") 100% 46px no-repeat;
}

.online-content {
  margin-top: 30px;
}

.online-content form {
  display: flex;
  flex-direction: column;
  align-items: start;
  color: #1B1B1B;
  font-size: 1.125rem;
  font-weight: 700;
}

.form-input-box {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  width: 100%;
  margin-bottom: 20px;
}

.main-block-input input {
  background: none;
  border-radius: 10px;
  border: 1px solid #D1D1D1;
}

.textarea-box {
  display: flex;
  flex-direction: column;
}

.form-input-box-textarea {
  width: 100%;
  margin-bottom: 42px;
}

.form-input-box-textarea textarea {
  border-radius: 10px;
  border: 1px solid #D1D1D1;
}

.form-main-btn {
  border-radius: 25px;
  border: 3px solid #000;
  background: none;
  color: #020202;
  text-align: center;
  font-size: 1.125rem;
  font-weight: 700;
  padding: 14px 75px;
}

.online .modal-header {
  justify-content: end;
  border: none;
}

.online .modal-header svg {
  cursor: pointer;
}

.online .modal-title {
  color: #06297B;
  text-align: center;
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 23px;
  font-style: normal;
  line-height: normal;
}

.online .modal-body {
  color: #000;
  text-align: center;
  font-size: 1.25rem;
  margin-bottom: 35px;
}

.online .modal-footer {
  justify-content: center;
  border: none;
}

.online .modal-dialog.modal-dialog-centered {
  max-width: 600px;
}

.online .modal-content {
  border-right: 10px;
}

.online .modal-footer {
  margin-bottom: 73px;
}

@media (max-width: 992px) {
  .form-input-box {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media (max-width: 576px) {
  .online .modal-title {
    font-size: 1.875rem;
  }
  .online .modal-footer button {
    font-size: 1.25rem;
  }
}
.main-news {
  padding: 50px 0 95px;
}

.main-news-title .main-title {
  color: #000;
}

.main-news-title .line-title {
  background: #000;
  height: 5px;
}

.main-news-content {
  margin-top: 50px;
}

.main-news-content .block-cards {
  margin-bottom: 50px;
}

.main-link {
  color: #000;
  font-size: 1.25rem;
  font-weight: 700;
}

.main-link:hover {
  color: #000 !important;
}

.banner-wrapper .banner-block {
  padding: 128px 0 130px;
  background: url("/img/banner-img.webp") no-repeat, lightgray 50%/cover no-repeat;
  background-size: cover;
}
.banner-wrapper .main-title {
  color: #fff;
}
.banner-wrapper .line-title {
  background: #D9D9D9;
}
.banner-wrapper .main-text {
  margin-top: 50px;
  color: #FFF;
  font-size: 1.25rem;
  max-width: 42%;
  margin-bottom: 50px;
}
.banner-wrapper .main-text p {
  margin-bottom: 8px;
}
.banner-wrapper .carousel-control-prev,
.banner-wrapper .carousel-control-next {
  display: inline-block;
  width: auto;
  top: inherit;
  position: unset;
  opacity: 1;
}
.banner-wrapper .carousel-control-prev-icon {
  display: none;
}
.banner-wrapper .carousel-control-next-icon {
  display: none;
}

@media (max-width: 1200px) {
  .banner-wrapper .main-text {
    max-width: 60%;
  }
}
@media (max-width: 992px) {
  .banner-wrapper .banner-block {
    background-size: cover;
    background-position: center;
  }
  .banner-wrapper .main-text {
    max-width: 80%;
  }
}
@media (max-width: 768px) {
  .banner-wrapper .main-text {
    max-width: 100%;
  }
}
.advocate {
  color: #1D1D1D;
  padding: 50px 0;
}

.advocate-wrapper .advocate-block {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
  align-items: center;
}
.advocate-wrapper .advocate-img-block {
  width: 100%;
  border-right: 10px;
}
.advocate-wrapper .advocate-img {
  width: 100%;
  border-right: 10px;
}
.advocate-wrapper .advocate-block-content {
  position: relative;
  font-weight: 700;
  font-size: 1.25rem;
}
.advocate-wrapper .advocate-block-title {
  margin-bottom: 22px;
  font-size: 2.5rem;
}
.advocate-wrapper .advocate-block-sub-title {
  font-size: 1.875rem;
  margin-bottom: 16px;
}
.advocate-wrapper .advocate-block-line {
  width: 120px;
  height: 5px;
  background: #1D1D1D;
  margin-bottom: 22px;
}
.advocate-wrapper .advocate-block-text {
  font-weight: 400;
  margin-bottom: 22px;
}
.advocate-wrapper .advocate-block-link {
  display: block;
  color: #1D1D1D;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 22px;
}
.advocate-wrapper .carousel-control-prev,
.advocate-wrapper .carousel-control-next {
  display: inline-block;
  width: auto;
  top: inherit;
  opacity: 1;
  position: unset;
}
.advocate-wrapper .carousel-control-prev-icon {
  display: none;
}
.advocate-wrapper .carousel-control-next-icon {
  display: none;
}

@media (max-width: 1200px) {
  .advocate-wrapper .advocate-block {
    grid-template-columns: 1fr;
  }
  .advocate-wrapper .advocate-img-block {
    width: min-content;
    margin: 0 auto;
  }
  .advocate-wrapper .advocate-img {
    width: min-content;
  }
}
@media (max-width: 768px) {
  .advocate-wrapper .advocate-img-block {
    width: 100%;
  }
  .advocate-wrapper .advocate-img {
    width: 100%;
  }
}
.basic-sections {
  padding: 50px 0 100px;
}

.basic-sections-title .main-title {
  color: #000;
}

.basic-sections-title .line-title {
  background: #000;
  height: 5px;
}

.basic-sections-content {
  margin-top: 50px;
}
.basic-sections-content__boxes {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  margin-bottom: 16px;
}
.basic-sections-content__box {
  display: flex;
  align-items: end;
  border-radius: 10px;
  height: 397px;
  text-decoration: none;
}
.basic-sections-content__box:hover {
  text-decoration: none !important;
}
.basic-sections-content__box:nth-child(1) {
  background: url("/img/basic-img-1.webp") no-repeat, lightgray 50%/cover no-repeat;
}
.basic-sections-content__box:nth-child(2) {
  background: url("/img/basic-img-2.webp") no-repeat, lightgray 50%/cover no-repeat;
}
.basic-sections-content__box-title {
  color: #FFF;
  font-size: 2.5rem;
  font-weight: 700;
  padding: 31px;
  max-width: 480px;
}
.basic-sections-content__sub-boxes {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}
.basic-sections-content__sub-box {
  border-radius: 10px;
  background: #0F3690;
  text-align: center;
  padding-top: 25px;
  padding-bottom: 25px;
}
.basic-sections-content__sub-box {
  text-decoration: none;
}
.basic-sections-content__sub-box:hover {
  text-decoration: none;
}
.basic-sections-content__sub-box:nth-child(2) {
  background: #092C80;
}
.basic-sections-content__sub-box:nth-child(3) {
  background: #0A38A6;
}
.basic-sections-content__sub-boxes.right-boxes .basic-sections-content__sub-box {
  border-radius: 10px;
  background: #0A38A6;
}
.basic-sections-content__sub-boxes.right-boxes .basic-sections-content__sub-box:nth-child(2) {
  background: #0F3690;
}
.basic-sections-content__sub-boxes.right-boxes .basic-sections-content__sub-box:nth-child(3) {
  background: #092C80;
}
.basic-sections-content__sub-box-svg {
  margin-bottom: 12px;
}
.basic-sections-content__sub-box-title {
  color: #FFF;
  font-size: 1.563rem;
  font-weight: 700;
  width: min-content;
  margin: 0 auto;
}

@media (max-width: 1400px) {
  .basic-sections-content__boxes {
    grid-template-columns: repeat(1, 1fr);
  }
  .basic-sections-content__box {
    background-size: cover !important;
  }
  .basic-sections-content__box.left {
    order: 1;
  }
  .basic-sections-content__box.right {
    order: 3;
  }
  .basic-sections-content__sub-boxes.left-boxes {
    order: 2;
  }
  .basic-sections-content__sub-boxes.right-boxes {
    order: 4;
  }
}
@media (max-width: 768px) {
  .basic-sections-content__sub-boxes {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media (max-width: 400px) {
  .basic-sections-content__box-title {
    padding: 19px;
  }
}
.statistic {
  background: url("/img/statistic.webp") no-repeat, lightgray 50%/cover no-repeat;
  background-size: cover;
  padding-top: 40px;
  padding-bottom: 30px;
}

.statistic-wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
}

.statistic-left {
  color: #FFF;
  font-weight: 700;
  width: 50%;
}
.statistic-left__title {
  font-size: 3.75rem;
  margin-bottom: 22px;
  max-width: 592px;
}
.statistic-left__date {
  font-size: 3.125rem;
  font-weight: normal;
  font-style: italic;
}

.statistic-right {
  color: #FFF;
  font-weight: 700;
  width: 50%;
}
.statistic-right__text {
  font-size: 1.875rem;
  margin-bottom: 6px;
  max-width: 80%;
}
.statistic-right__number {
  font-size: 5rem;
  font-style: italic;
}

.statistic-right-block {
  margin-bottom: 22px;
}

@media (max-width: 992px) {
  .statistic-wrapper {
    flex-direction: column;
    align-items: start;
  }
  .statistic-left {
    width: 100%;
  }
  .statistic-right {
    width: 100%;
  }
  .statistic-left .statistic-left__title,
  .statistic-left .statistic-left__date {
    max-width: 100%;
  }
  .statistic-right__text {
    max-width: 100%;
  }
  .statistic-left {
    margin-bottom: 64px;
  }
}
@media (max-width: 576px) {
  .statistic-left .statistic-left__title,
  .statistic-left .statistic-left__date {
    font-size: 2.5rem !important;
  }
}
.faq {
  background: url("/img/faq.webp") no-repeat, lightgray 50%/cover no-repeat;
  background-size: cover;
  padding-top: 100px;
  padding-bottom: 40px;
}

.faq-wrapper {
  display: grid;
  grid-template-columns: 0.7fr 1fr;
  grid-gap: 20px;
}

.faq-title {
  max-width: 479px;
}

.faq-title .main-title {
  color: #FFF !important;
  font-size: 3.75rem;
  font-weight: 700;
}

.faq-title .line-title {
  background: #fff;
  height: 5px;
}

.faq-content-title {
  color: #FFF;
  font-size: 2.25rem;
  margin-bottom: 22px;
}

.faq-content-text {
  color: #FFF;
  font-size: 1.25rem;
  margin-bottom: 22px;
}

.faq-content-link {
  color: #fff;
  font-size: 1.5rem;
  font-weight: 700;
}

.faq-content-link:hover {
  color: #fff !important;
}

@media (max-width: 1200px) {
  .faq-wrapper {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 23px;
  }
}
@media (max-width: 768px) {
  .faq-content-title {
    font-size: 2.25rem;
  }
  .faq-title .main-title {
    font-size: 2.5rem;
  }
}
.check {
  padding: 50px 0;
}

.check-wrapper {
  border-radius: 10px;
  background: #0F3690;
}

.check-text-content {
  display: flex;
  align-items: center;
  padding: 36px 130px;
}

.check-title {
  color: #FFF;
  font-size: 3.75rem;
  font-weight: 700;
}

.check .main-button {
  color: #0F3690;
  border: 3px solid #0F3690;
  background: #fff;
  outline: 4px solid #fff;
}

@media (max-width: 1200px) {
  .check-text-content {
    flex-direction: column;
    text-align: center;
  }
  .check-title {
    margin-bottom: 46px;
  }
}
@media (max-width: 992px) {
  .check-title {
    font-size: 2.5rem;
  }
}
.fast-links {
  padding: 50px 0 100px;
  background: #F9F9F9;
}

.fast-links-content {
  margin-top: 50px;
}

.fast-links-title .main-title {
  color: #000;
}

.fast-links-title .line-title {
  background: #000;
  height: 5px;
}

.fast-links-content-boxes {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 20px;
}

.fast-links-content-box {
  border-radius: 10px;
  border: 1px solid #d9d9d9;
  background: #fff;
  padding: 17px 6px 9px;
  text-align: center;
  text-decoration: none;
  color: #111;
}

.fast-links-content-box-img {
  height: 80px;
}

.fast-links-content-box-text {
  color: #111;
  text-align: center;
  font-size: 1rem;
}

@media (max-width: 1400px) {
  .fast-links-content-boxes {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (max-width: 992px) {
  .fast-links-content-boxes {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 768px) {
  .fast-links-content-boxes {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 576px) {
  .fast-links-content-boxes {
    grid-template-columns: repeat(1, 1fr);
  }
}
.office {
  padding: 50px 0 100px;
}

.office-title .main-title {
  color: #000;
}
.office-title .line-title {
  background: #000;
  height: 5px;
}

.office-map {
  width: 100%;
  height: 500px;
  margin-top: 50px;
}

.office-map iframe {
  width: 100%;
  height: 500px;
}

.office-map-link {
  color: #000;
  font-size: 1.125rem;
  font-weight: 700;
  text-align: center;
  display: block;
  margin-top: 28px;
  margin-bottom: 20px;
}

.office-map-link:hover {
  color: #000 !important;
}

@media (max-width: 576px) {
  .office {
    margin-bottom: 30px;
  }
}
.one-faq {
  padding: 46px 0 100px 0;
  background: url("/img/content-bg.png") 100% 46px no-repeat;
}

.one-faq-content {
  color: #000;
  font-size: 1.25rem;
  margin-top: 50px;
}

.one-faq-content-text {
  color: #000;
  font-size: 1.25rem;
}

.one-faq-content-text p {
  color: #000;
  font-size: 1.25rem;
}

.one-faq-content-text a {
  text-decoration: none;
  color: #000;
  font-size: 1.25rem;
}

.free-consultation {
  padding: 46px 0 100px 0;
  background: url("/img/content-bg.png") 100% 46px no-repeat;
}

.free-consultation-content {
  margin-top: 22px;
  color: #000;
}

.free-consultation-content-text {
  max-width: 850px;
  font-size: 1.25rem;
  color: #000;
}

.free-consultation-content-text:not(:last-child) {
  margin-bottom: 75px;
}

.free-consultation-content-text p {
  font-size: 1.25rem;
  color: #000;
}

.free-consultation-content-text p:not(:last-child) {
  margin-bottom: 20px;
}

.free-consultation-content-text a {
  text-decoration: none;
  color: #000;
}

.free-boxes {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  text-align: center;
}

.free-box {
  border-radius: 10px;
  background: #0A38A6;
  padding: 81px 29px;
  text-decoration: none;
}

.free-box:nth-child(2n) {
  background: #082F89;
}

.free-box:nth-child(3n) {
  background: #08266D;
}

.free-box:nth-child(4n) {
  background: #021B54;
}

.free-box:nth-child(5n) {
  background: #0A38A6;
}

.free-box:nth-child(6n) {
  background: #082F89;
}

.free-box:nth-child(7n) {
  background: #08266D;
}

.free-box:nth-child(8n) {
  background: #021B54;
}

.free-box-svg {
  margin-bottom: 24px;
}

.free-box-title {
  color: #FFF;
  font-size: 1.563rem;
  font-weight: 700;
}

.free-consultation .free-boxes {
  margin-bottom: 52px;
}

@media (max-width: 1400px) {
  .free-boxes {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 768px) {
  .free-boxes {
    grid-template-columns: repeat(1, 1fr);
  }
}
.free-advocate {
  padding: 46px 0 100px 0;
  background: url("/img/content-bg.png") 100% 46px no-repeat;
}

.free-advocate-content {
  margin-top: 22px;
  color: #000;
}

.free-advocate-content-text {
  max-width: 850px;
  font-size: 1.25rem;
  color: #000;
}

.free-advocate-content-text:not(:last-child) {
  margin-bottom: 75px;
}

.free-advocate-content-text p {
  font-size: 1.25rem;
  color: #000;
}

.free-advocate-content-text p:not(:last-child) {
  margin-bottom: 20px;
}

.free-advocate-content-text a {
  text-decoration: none;
  color: #000;
}

.free-advocate .free-boxes {
  margin-bottom: 52px;
}
@supports (-webkit-appearance:none){
.section-footer{background:url("/img/footer-bg.webp") 0 no-repeat, #06297B}
.law{background:url("/img/content-bg.webp") 100% 0 no-repeat}
.service{background:url("/img/content-bg.webp") 100% 46px no-repeat}
.vacancies{background:url("/img/content-bg.webp") 100% 46px no-repeat}
.purchase{background:url("/img/content-bg.webp") 100% 46px no-repeat}
.partners{background:url("/img/content-bg.webp") 100% 46px no-repeat}
.about{background:url("/img/content-bg.webp") 100% 6px no-repeat}
.standard-base{background:url("/img/content-bg.webp") 100% 46px no-repeat}
.history{background:url("/img/content-bg.webp") 100% 46px no-repeat}
.symbolic{background:url("/img/content-bg.webp") 100% 46px no-repeat}
.vacancy{background:url("/img/content-bg.webp") 100% 46px no-repeat}
.one-purchase{background:url("/img/content-bg.webp") 100% 46px no-repeat}
.hotline{background:url("/img/content-bg.webp") 100% 46px no-repeat}
.bus{background:url("/img/content-bg.webp") 100% 46px no-repeat}
.docs{background:url("/img/content-bg.webp") 100% 46px no-repeat}
.acts{background:url("/img/content-bg.webp") 100% 46px no-repeat}
.announcements{background:url("/img/content-bg.webp") 100% 46px no-repeat}
.one-announcement{background:url("/img/content-bg.webp") 100% 46px no-repeat}
.news{background:url("/img/content-bg.webp") 100% 46px no-repeat}
.one-news{background:url("/img/content-bg.webp") 100% 46px no-repeat}
.video-materials{background:url("/img/content-bg.webp") 100% 46px no-repeat}
.contacts-centre{background:url("/img/content-bg.webp") 100% 46px no-repeat}
.contact-territory{background:url("/img/content-bg.webp") 100% 46px no-repeat}
.territory-all-regions{background:url("/img/content-bg.webp") 100% 46px no-repeat}
.territory-region{background:url("/img/content-bg.webp") 100% 46px no-repeat}
.coordinators{background:url("/img/content-bg.webp") 100% 46px no-repeat}
.coordinator-region{background:url("/img/content-bg.webp") 100% 46px no-repeat}
.online{background:url("/img/content-bg.webp") 100% 46px no-repeat}
.one-faq{background:url("/img/content-bg.webp") 100% 46px no-repeat}
.free-consultation{background:url("/img/content-bg.webp") 100% 46px no-repeat}
.free-advocate{background:url("/img/content-bg.webp") 100% 46px no-repeat}
}

 @media (max-width: 860px){

@supports (-webkit-appearance:none){

}

}

 @media (max-width: 1250px){

@supports (-webkit-appearance:none){

}

}

 @media (max-width: 900px){

@supports (-webkit-appearance:none){

}

}

 @media (max-width: 400px){

@supports (-webkit-appearance:none){

}

}

 @media (max-width: 768px){

@supports (-webkit-appearance:none){

}

}

 @media (max-width: 430px){

@supports (-webkit-appearance:none){

}

}

 @media (max-width: 1300px){

@supports (-webkit-appearance:none){

}

}

 @media (max-width: 850px){

@supports (-webkit-appearance:none){

}

}

 @media (max-width: 992px){

@supports (-webkit-appearance:none){

}

}

 @media (max-width: 676px){

@supports (-webkit-appearance:none){

}

}

 @media (max-width: 1200px){

@supports (-webkit-appearance:none){

}

}

 @media (max-width: 576px){

@supports (-webkit-appearance:none){

}

}

 @media (max-width: 1356px){

@supports (-webkit-appearance:none){

}

}

 @media (max-width: 1176px){

@supports (-webkit-appearance:none){

}

}

 @media (max-width: 767px){

@supports (-webkit-appearance:none){

}

}

 @media (max-width: 568px){

@supports (-webkit-appearance:none){

}

}

 @media (max-width: 1400px){

@supports (-webkit-appearance:none){

}

}
