.free-advocate {
  padding: 46px 0 100px 0;
  background: url("/img/content-bg.png") 100% 46px no-repeat;
}

.free-advocate-content {
  margin-top: 22px;
  color: #000;
}
.free-advocate-content-text {
  max-width: 850px;
  font-size: 1.25rem;
  color: #000;
}
.free-advocate-content-text:not(:last-child) {
  margin-bottom: 75px;
}
.free-advocate-content-text p{
  font-size: 1.25rem;
  color: #000;
}
.free-advocate-content-text p:not(:last-child){
  margin-bottom: 20px;
}
.free-advocate-content-text a{
  text-decoration: none;
  color: #000;
}
.free-boxes {
}
.free-box {
}
.free-box-svg {
}
.free-box-title {
}

.free-advocate {
  .free-boxes {
    margin-bottom: 52px;
  }
}
