.hotline {
  padding: 46px 0 144px 0;
  background: url("/img/content-bg.png") 100% 46px no-repeat;
}

.hotline-content {
  color: #000;
  font-size: 1.25rem;
  margin-top: 40px;

  // .hotline-content__text

  &__text {
    margin-bottom: 64px;
    max-width: 85%;
  }

  &__text a{
    color: #000;
    text-decoration: none;
  }
}

.main-button {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 1.75rem;
  font-weight: 500;
  padding: 12px 70px;
  border-radius: 10px;
  border: 2px solid #FFF;
  background: #06297B;
  outline: 4px solid #06297B;
}

.main-button svg {
  margin-left: 11px;
}

@media (max-width: 576px) {
  .main-button {
    padding: 10px 29px;
  }
}
