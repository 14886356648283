.coordinator-region {
  padding: 46px 0 131px 0;
  background: url("/img/content-bg.png") 100% 46px no-repeat;
}

.coordinator-region-content {

}
.sub-main-text {
}
.coordinator-region-content-title {
  color: #000;
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 37px;
}
.coordinator-region-content-boxes {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}
.coordinator-region-content-box {
  text-align: center;
  border-radius: 10px;
  border: 1px solid #ECECEC;
  background: #FFF;
  box-shadow: 4px 4px 15px 0 rgba(0, 0, 0, 0.25);
}
.coordinator-region-content-box-img {
  border-radius: 150px;
  margin-bottom: 20px;
}
.coordinator-region-content-box-img img{
  border-radius: 150px;
}
.coordinator-region-content-box-title {
  color: #202020;
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 12px;
}
.coordinator-region-content-title-line {
  width: 120px;
  height: 4px;
  background: #202020;
  margin: 12px auto 0 auto;
}
.coordinator-region-content-box-text {
  color: #000;
  font-size: 1.125rem;
  font-weight: 400;
  margin-bottom: 25px;
}
.coordinator-region-content-box-address {
  color: #000;
  font-size: 1.125rem;
  font-weight: 400;
}
.coordinator-region-content-sub-box {
  padding: 0 57px 30px;
}

.coordinator-region-content-boxes-title {
  color: #FFF;
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  border-radius: 10px 10px 0 0;
  background: #0A38A6;
  padding: 35px;
  margin-bottom: 10px;
}

.coordinator-region-content-box-text p:not(:last-child){
  margin-bottom: 25px;
}
@media (max-width: 1200px) {
  .coordinator-region-content-boxes {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .coordinator-region-content-boxes {
    grid-template-columns: repeat(1, 1fr);
  }
}
