.bus {
  padding: 46px 0 92px 0;
  background: url("/img/content-bg.png") 100% 46px no-repeat;
}

.bus-content {
  margin-top: 54px;
  margin-bottom: 70px;
  color: #000;
  font-size: 1.25rem;
  overflow: hidden!important;

  // .bus-content__left

  &__box-text {
  }

  // .bus-content__left-text

  &__text {
  }

  &__text a{
    color: #000;
    text-decoration: none;
  }

  // .bus-content__right

  &__box-img {
    float: right;
    border-radius: 10px;
    margin-left: 40px;
  }

  // .bus-content__right-img

  &__img {
    border-radius: 10px;
  }
}

.cards-video {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.card-video {
  color: #000;
  font-size: 1.125rem;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}

.box-video {
  width: 100%;
  border-radius: 10px 10px 0 0;
  height: 300px;
}

.box-video iframe {
  width: 100%;
  height: 300px;
  border-radius: 10px 10px 0 0;
}

.title-video {
  font-weight: 700;
  padding: 32px 18px 58px;
}

@media (max-width: 1200px) {
  .bus-content {
    display: flex;
    flex-direction: column;
    overflow: inherit;

    &__box-text {
      margin-bottom: 50px;
    }

    &__box-img {
      margin: auto;
      float: inherit;
      order: 2;
    }

    &__img {
      width: 100%;
    }
  }

  .cards-video {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .cards-video {
    grid-template-columns: repeat(1, 1fr);
  }
}
