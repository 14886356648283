.one-faq {
  padding: 46px 0 100px 0;
  background: url("/img/content-bg.png") 100% 46px no-repeat;
}

.one-faq-content {
  color: #000;
  font-size: 1.25rem;
  margin-top: 50px;
}
.one-faq-content-text {
  color: #000;
  font-size: 1.25rem;
}

.one-faq-content-text p{
  color: #000;
  font-size: 1.25rem;
}

.one-faq-content-text a{
  text-decoration: none;
  color: #000;
  font-size: 1.25rem;
}
