.section-footer {
  background: url("/img/footer-bg.png") 0 no-repeat, #06297B;
  padding: 57px 0 42px 0;
  color: #fff;
}

.section-footer a {
  text-decoration: none;
  color: #fff;
  font-weight: 700;
}

.wrapper-footer {

}

.footer-info {
  display: grid;
  grid-template-columns: 1.2fr 1.2fr 1fr 0.5fr;
  margin-bottom: 97px;
  grid-gap: 10px;

  // .footer-info__block-img

  &__block-img {
    display: flex;
  }

  // .footer-info__text-img

  &__text-img {
    margin-left: 9px;
    max-width: 114px;
    font-weight: 700;
  }

  // .footer-info__block-address

  &__block-address {
    font-weight: 700;
    font-size: 1.125rem;
  }

  // .footer-info__block-address-text

  &__block-address-text {
    margin-bottom: 9px;
  }

  &__block-content {
    display: flex;
    flex-direction: column;
  }

  &__block-content a:not(:last-child) {
    margin-bottom: 11px;
  }

  &__block-content-phone {
    text-decoration: underline !important;
  }

  // .footer-info__block-social
  &__block-social {
    font-size: 1.125rem;
  }

  &__block-social-text {
    margin-bottom: 13px;
	  font-weight: 700;
  }

  // .footer-info__block-social-icon

  &__block-social-icon {
  }

  &__block-social-icon a:not(:last-child) {
    margin-right: 10px;
  }

  // .footer-info__block-social-icon-fb

  &__block-social-icon-fb {
  }

  // .footer-info__block-social-icon-yt

  &__block-social-icon-yt {
  }

  // .footer-info__block-social-icon-tt

  &__block-social-icon-tt {
  }
}

.block-info-img {
}

.info-img-first {
  margin-right: 15px;
}

.info-img-second {
  margin-right: 18px;
}

.info-img-third {
  display: flex;
  align-items: start;
}

.footer-sites {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 82px;
  grid-gap: 10px;
  // .footer-sites__right

  &__right {
    display: flex;
  }

  // .footer-sites__right-img

  &__right-img {
    margin-right: 18px;
  }

  // .footer-sites__right-text

  &__right-text {
    max-width: 434px;
    font-size: 0.875rem;
  }

  // .footer-sites__left

  &__left {
    display: flex;
  }

  // .footer-sites__left-img

  &__left-img {
    margin-right: 10px;
  }

  // .footer-sites__left-text

  &__left-text {
    font-weight: 400;
    max-width: 434px;
    font-size: 0.875rem;
  }
}


.footer-copyright {
	border-top: 2px solid #fff;
	padding-top: 14px;
	font-size: .875rem;
}

@media (max-width: 1300px) {
  .footer-info {
    grid-template-columns: repeat(2, 1fr);
  }

  .footer-info__block-img {
    margin-bottom: 32px;
  }

  .footer-info__block-address-text {
    max-width: 350px;
  }
}

@media (max-width: 850px) {
  .footer-info {
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 61px;
  }

  .block-info-img {
    display: flex;
  }

  .footer-info__block-img {
    margin-bottom: 32px;
  }

  .footer-info__block-address-text {
    max-width: 350px;
  }

  .footer-info__block-address {
    margin-bottom: 32px;
  }

  .footer-info__block-content {
    margin-bottom: 32px;
    font-size: 1.125rem;
  }

  .footer-sites {
    flex-direction: column;
  }

  .footer-sites__right {
    margin-bottom: 26px;
  }

  .footer-sites__right,
  .footer-sites__left {
    flex-direction: column;
  }

  .footer-sites__right img,
  .footer-sites__left img {
    margin-bottom: 17px;
  }
}

@media (max-width: 400px) {
  .info-img-first {
    margin-right: 7px;
  }

  .info-img-second {
    margin-right: 7px;
  }

  .footer-info__text-img {
    font-size: 0.875rem;
  }
  .footer-info__text-img {
    margin-left: 5px;
  }
}
