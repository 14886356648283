.free-consultation {
  padding: 46px 0 100px 0;
  background: url("/img/content-bg.png") 100% 46px no-repeat;
}

.free-consultation-content {
  margin-top: 22px;
  color: #000;
}
.free-consultation-content-text {
  max-width: 850px;
  font-size: 1.25rem;
  color: #000;
}
.free-consultation-content-text:not(:last-child) {
  margin-bottom: 75px;
}
.free-consultation-content-text p{
  font-size: 1.25rem;
  color: #000;
}
.free-consultation-content-text p:not(:last-child){
  margin-bottom: 20px;
}
.free-consultation-content-text a{
  text-decoration: none;
  color: #000;
}
.free-boxes {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  text-align: center;
}
.free-box {
  border-radius: 10px;
  background: #0A38A6;
  padding: 81px 29px;
  text-decoration: none;
}
.free-box:nth-child(2n){
  background: #082F89;
}
.free-box:nth-child(3n){
  background: #08266D;
}
.free-box:nth-child(4n){
  background: #021B54;
}

.free-box:nth-child(5n){
  background: #0A38A6;
}
.free-box:nth-child(6n){
  background: #082F89;
}
.free-box:nth-child(7n){
  background: #08266D;
}
.free-box:nth-child(8n){
  background: #021B54;
}

.free-box-svg {
  margin-bottom: 24px;
}
.free-box-title {
  color: #FFF;
  font-size: 1.563rem;
  font-weight: 700;
}

.free-consultation {
  .free-boxes {
    margin-bottom: 52px;
  }
}

@media (max-width: 1400px) {
  .free-boxes {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .free-boxes {
    grid-template-columns: repeat(1, 1fr);
  }
}
