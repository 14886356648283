body {
  color: #fff;
  font-size: 1.125rem;
  font-family: 'Inter', sans-serif;
  font-style: normal!important;
  line-height: normal!important;
  font-weight: 400;
}

input {
  font-size: 0.75rem;
  line-height: normal;
}

.my-container {
  max-width: 1512px;
  padding: 0 46px;
  margin: 0 auto;
}

a::after {
  display: none!important;
}

a:hover {
  color: inherit!important;
}

@media (max-width: 860px) {
  .my-container {
    padding: 0 14px;
  }
}
