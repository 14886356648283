.header {
  color: #fff;
  // .header__top

  &__top {
    background: #021F7F;
    padding: 11px 0 15px 0;
  }

  &__top .my-container {
    display: flex;
    justify-content: space-between;
  }

  // .header__top-text

  &__top-text {
    max-width: 510px;
    font-size: 1.125rem;
    font-weight: 700;
  }

  // .header__top-block-phone

  &__top-block-phone {
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 1.75rem;
    font-weight: 500;
  }

  // .header__top-phone-text

  &__top-phone-text {
    margin-right: 7px;
  }

  // .header__bottom

  &__bottom {
    padding: 22px 0 51px 0;
    background: var(--Main-blue, #06297B);
  }

  &__bottom .my-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  // .header__bottom-content

  &__bottom-content {
    font-size: 1rem;

    a.header-contact {
      font-weight: 700;
    }

    a.header-contact:hover {
      color: #98b3ff !important;
    }

    .header__bottom-content-elem-lang button {
      font-size: 1.125rem;
    }

    .navbar-nav.header-navbar-nav-items {
      justify-content: end;
      margin-right: -19px;
      margin-left: 15px;
    }

    .navbar-nav.header-navbar-nav-items button {
      background: none;
      padding: 0;
      border: none;
      color: #fff;
      font-weight: 700;
    }

    .navbar-nav.header-navbar-nav-items button:hover {
      color: #98b3ff;
    }

    .navbar-nav.header-navbar-nav-items button:after {
      display: none;
    }
  }

  // .header__bottom-content-elems

  &__bottom-content-elems {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: end;
  }

  // .header__bottom-content-elem-search

  &__bottom-content-elem-search {
    height: 30px;
  }

  // .header__bottom-content-elem-eye

  &__bottom-content-elem-eye {
    margin-left: 20px;
    margin-right: 26px;
  }

  // .header__bottom-content-elem-lang

  &__bottom-content-elem-lang {

  }

  // .header__bottom-content-elem-lang-ky

  &__bottom-content-elem-lang-ky,
  &__bottom-content-elem-lang-ru {
    background: none;
    border: none;
    padding: 0;
    color: #CACACA;
  }

  &__bottom-content-elem-lang-ky {
    margin-right: 12px;
  }

  &__bottom-content-elem-lang-ky.active,
  &__bottom-content-elem-lang-ru.active {
    font-weight: 700;
    color: #FFF;
  }

  // .header__bottom-content-elem-lang-ru

  &__bottom-content-elem-lang-ru {

  }
}

.header__bottom-mobile-content {
  display: none;
}

.header-wrapper {
}

.container {
}

.block-info-img {
  display: flex;
}

.info-img-first {
}

.info-img-second {
}

.info-img-third {
}

.active {
}

.navbar-nav {
}

.navbar-nav.header-navbar-nav-items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.navbar-nav.header-navbar-nav-items a {
  padding: 0;
  text-decoration: none;
  color: #fff;
}

.nav-item.dropdown.header-nav-item ul.dropdown-menu.show {
  border: none;
  inset: 7px auto auto 0 !important;
  width: 280px;
}

.nav-item.dropdown.header-nav-item ul.dropdown-menu.show a {
  white-space: inherit;
}

.navbar-nav.header-navbar-nav-items ul.dropdown-menu.show {
  border-radius: inherit;
  margin: 0 !important;
  background: #06297B;
  padding-left: 45px;
  list-style-type: disc !important;
  padding-right: 24px;
  color: #fff !important;
}

.navbar-nav.header-navbar-nav-items .dropdown-item {
  margin-bottom: 11px;
}

.navbar-nav.header-navbar-nav-items .dropdown-item:hover {
  background: none;
}

.navbar-nav.header-navbar-nav-items li {
  margin-right: 19px;
  margin-bottom: 7px;
}

.header-nav-item-sub-nav {
  font-weight: 700;
  margin-bottom: 5px;
}

.element-input {
  border: 1px solid #FFF;
  border-radius: 10px;
  padding: 7px 10px;
  width: 285px;
  max-width: 285px;
  background: none;
  color: #fff;
}

.element-input:focus-visible {
  outline: none;
}

.header__bottom-content-elem-search {
  position: relative;
}

.element-input::placeholder {
  font-weight: 700;
  font-style: italic;
  font-size: 0.75rem;
  color: #fff;
}

.header__bottom-content-elem-search svg {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  right: 18px;
}

@media (max-width: 1250px) {

  .header__top-phone-text {
    white-space: nowrap;
  }

  .navbar-nav.header-navbar-nav-items {
    justify-content: flex-end;
    margin-right: -19px;
  }

  .navbar-nav.header-navbar-nav-items li {
    margin-right: 19px;
    margin-bottom: 10px;
  }


}

@media (max-width: 900px) {
  .header__bottom-content {
    display: none;
  }

  .header__bottom-mobile-content {
    display: block;
  }

  .footer-info {
    grid-template-columns: repeat(2, 1fr);
  }

  .footer-info__block-img {
    margin-bottom: 32px;
  }

  .footer-info__block-address-text {
    max-width: 350px;
  }

  .navbar-nav.header-navbar-nav-items li {
    margin-right: 19px;
  }

  .header {

    &__top .my-container {
      align-items: center;
      flex-direction: column;
      text-align: center;
    }

    &__top-text {
      font-size: .875rem;
      margin-bottom: 11px;
    }

  }
}

@media (max-width: 400px) {
  .header__bottom-mobile-content .menu {

    .header__bottom-content-elem-search {
      margin-right: auto;
    }

    .header__bottom-content-elem-search input {
      width: 100%;
    }

    .header__bottom-content-elem-eye {
      margin: 0 10px;

    }

    .header__bottom-content-elem-lang-ky {
      margin-right: 5px;
    }
  }
}

//HAMBURGER

.nav {
  input {
    display: none;
  }

  label {
    cursor: pointer;
  }
}

.nav {
  user-select: none;
}

.nav__showmenu {
  position: relative;
  z-index: 3;
  display: inline-block;
  margin-left: -10px;
  padding: 0;
  top: 2px;
  font-size: 40px;
}

.nav__back {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color .3s;
}

.menu, .menu__submenu {
  list-style: none;
  padding: 0;
  color: #fff;
  overflow: hidden;
}

.menu {
  position: absolute;
  top: 225px;
  left: 0;
  display: block;
  width: 100%;
  margin: 0;
  padding: 17px 0;
  text-transform: uppercase;
  line-height: 2em;
  background: #06297B;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  transform: translateX(-100%);
  transition: transform .3s;
  will-change: transform;
  backface-visibility: hidden;
  z-index: 10;
}

.menu__submenu {
  font-size: .8em;
  background-color: #ddd;
  max-height: 0;
  transition: max-height .3s ease-in-out;
}

.menu__showsub {
  position: absolute;
  right: 0;
  margin-top: -34px;
  padding: 10px;
  line-height: normal;
}

.menu__item {
  display: block;
  text-align: center;
  text-decoration: none;
  color: #fff;
}

.menu__submenu .menu__item {
  color: #000;
}

.nav__check:checked ~ .nav__back {
  background-color: rgba(0, 0, 0, .2);
  bottom: 0;
}

.menu__item:hover, .nav__showmenu:hover, .menu__showsub:hover {
  opacity: 0.7;
}

.menu::-webkit-scrollbar {
  display: none;
}

.nav__check:checked + .nav__showmenu {
  color: #fff;
}

.menu__check:checked ~ .menu__submenu {
  max-height: 100vh;
}

.menu__check:checked + .menu__showsub {
  transform: rotate(180deg);
}

.nav__check:checked ~ .menu {
  transform: translateX(0);
}

.menu {
  button {
    width: 100%;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    border: none;
  }

  button:active {
  }

  button:after {
    content: inherit;
  }

  .dropdown-menu.show {
    position: static !important;
    transform: inherit !important;
    border: none;
    background: inherit;
  }

  .dropdown-item {
    background: inherit;
    color: #fff;
  }
}

.element-input-box-mobile {
  display: none;
}

.header__bottom-content-elem-search input {
  display: block;
}

.menu .my-container {
  flex-direction: column;
  justify-content: inherit;
  align-items: inherit;

  .header {
    &__bottom-content-elem-eye {
      margin-left: 19px;
      margin-right: 14px;
    }

    &__bottom-content-elem-lang-ky,
    &__bottom-content-elem-lang-ru {
      background: none;
      border: none;
      padding: 0;
      color: #CACACA;
    }

    &__bottom-content-elem-lang-ky.active,
    &__bottom-content-elem-lang-ru.active {
      font-weight: 700;
      color: #FFF;
    }
  }


  .menu-burger-nav {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    padding: 0;
    list-style: none;

    .header-contact.header-contact-mobile {
      color: #fff;
      text-decoration: none;
    }

    .header-contact.header-contact-mobile:hover {
      color: #98b3ff;
    }

    .nav-item.dropdown.menu-main-link button {
      text-align: start;
      padding: 0;
      font-size: 1.375rem;
    }

    .nav-item.dropdown.menu-main-link ul {
      padding-left: 25px;
      list-style: disc;
      color: #fff;
    }

    .nav-item.dropdown.menu-main-link:not(:last-child) {
      margin-bottom: 6px;
    }

    .nav-item.dropdown.menu-main-link .btn.dropdown-toggle.show svg {
      transform: rotate(-180deg);
    }

    .nav-item.dropdown.menu-main-link button:focus {
      box-shadow: none;
    }

    .nav-item.dropdown.menu-main-link button:hover {
      color: #98b3ff;
    }

    .nav-item.dropdown.menu-main-link button svg {
      margin-left: 13px;
    }

    .dropdown-item {
      padding: 0;
      font-size: 1.25rem;
      text-transform: capitalize;
    }

    .dropdown-item:hover {
      color: #98b3ff !important;
    }

    .header-nav-item-sub-nav {
      font-size: 1.25rem;
      color: #fff;
    }
  }


  .header__bottom-content-elems {

  }

  .header__bottom-content-elem-lang {
    display: flex;
  }

  .element-input {
    padding: 9px 10px;
    height: 30px;
    width: 207px;
  }
}


//eyeStaticBackdrop
#eyeStaticBackdrop, .menu__submenu .menu__item {
  color: #000
}

#eyeStaticBackdrop .modal-content {
  padding: 20px 20px 0;
  border-radius: 10px;
}

#eyeStaticBackdrop .modal-footer {
  border: 0;
  padding: 0 0 20px
}

#eyeStaticBackdrop .modal-footer button {
  margin: 0
}

#eyeStaticBackdrop .modal-dialog {
  max-width: max-content
}

#eyeStaticBackdrop .modal-footer {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between
}

#eyeStaticBackdrop .eye-color, #eyeStaticBackdrop .eye-font {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  margin-bottom: 20px
}

#eyeStaticBackdrop .eye-color .eye-color-text, #eyeStaticBackdrop .eye-color .eye-font-text, #eyeStaticBackdrop .eye-font .eye-color-text, #eyeStaticBackdrop .eye-font .eye-font-text {
  margin: 0 40px 0 0
}

#eyeStaticBackdrop .eye-color .eye-color-btns, #eyeStaticBackdrop .eye-color .eye-font-btns, #eyeStaticBackdrop .eye-font .eye-color-btns, #eyeStaticBackdrop .eye-font .eye-font-btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

#eyeStaticBackdrop .eye-color-btn, #eyeStaticBackdrop .eye-font-btn {
  background: inherit;
  border: 1px solid #1d4d7b
}

#eyeStaticBackdrop .eye-font-btn {
  padding: 5px 10px
}

#eyeStaticBackdrop .eye-color-btn {
  padding: 10px 15px
}

#eyeStaticBackdrop .eye-color-btn:not(:last-child) {
  margin-right: 20px
}

#eyeStaticBackdrop .eye-font-btn:hover {
  background: #033a70;
  color: #fff
}

#eyeStaticBackdrop .eye-font-btn:nth-child(1) {
  font-size: 1rem;
  margin-right: 10px
}

#eyeStaticBackdrop .eye-font-btn:nth-child(2) {
  font-size: 1.25rem;
  margin-right: 10px
}

#eyeStaticBackdrop .eye-font-btn:nth-child(3) {
  font-size: 1.5rem
}

#eyeStaticBackdrop .eye-color-btn:nth-child(2) {
  background: #000;
  color: #fff
}

#eyeStaticBackdrop .eye-color-btn:nth-child(3) {
  background: #033a70;
  color: #fff
}

#eyeStaticBackdrop .eye-btn-close, #eyeStaticBackdrop .eye-btn-reset {
  background: #1d4d7b;
  color: #fff
}

@media (max-width: 768px) {
  #eyeStaticBackdrop .modal-footer {
    flex-direction: column;
  }
  #eyeStaticBackdrop .eye-color, #eyeStaticBackdrop .eye-font {
    flex-direction: column;
    justify-content: center;
  }

  #eyeStaticBackdrop .eye-font-text,
  #eyeStaticBackdrop .eye-color-text {
    margin: 0 0 20px 0 !important;
  }

  #eyeStaticBackdrop .modal-footer button:not(:last-child) {
    margin-bottom: 10px;
  }

  #eyeStaticBackdrop .modal-dialog {
    max-width: 100%;
    padding: 0 10px;
  }
}

@media (max-width: 430px) {

  #eyeStaticBackdrop .eye-font-btns-size {
    flex-direction: column;
  }

  #eyeStaticBackdrop .eye-font-btns-size .eye-font-btn {
    margin: 0 0 10px 0;
  }
}

//btn-up

.btn-up {
  position: fixed;
  right: 20px;
  bottom: 20px;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  z-index: 99999
}

.btn-up svg {
  border-radius: 50%;
}

.btn-up_hide {
  display: none
}

.path5 {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(.95);
  }
  50% {
    transform: scale(1);
    opacity: 0.7;
  }
  100% {
    transform: scale(.95);
  }
}

.path1 {
  opacity: 0;
  animation: wifiAnimation 3s infinite;
}

.path2 {
  opacity: 0;
  animation: wifiAnimation 3s infinite;
}

.path3 {
  opacity: 0;
  animation: wifiAnimation 3s infinite;
  animation-delay: 400ms;
}

.path4 {
  opacity: 0;
  animation: wifiAnimation 3s infinite;
  animation-delay: 800ms;
}

@-o-keyframes wifiAnimation {
  0% {
    opacity: 0.4
  }
  15% {
    opactiy: 1
  }
  30% {
    opactiy: 0.1
  }
  100% {
    opactiy: 0.1;
  }
}

@-moz-keyframes wifiAnimation {
  0% {
    opacity: 0.4
  }
  15% {
    opactiy: 1
  }
  30% {
    opactiy: 0.1
  }
  100% {
    opactiy: 0.1;
  }
}

@-webkit-keyframes wifiAnimation {
  0% {
    opacity: 0.4
  }
  15% {
    opactiy: 1
  }
  30% {
    opactiy: 0.1
  }
  100% {
    opactiy: 0.1;
  }
}
