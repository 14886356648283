.territory-all-regions {
  padding: 46px 0 147px 0;
  background: url("/img/content-bg.png") 100% 46px no-repeat;
}
.territory-all-regions-content {

}
.territory-all-regions-box-main {
  border-radius: 10px;
  background: #001560;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  padding: 22px 35px;
  max-width: 460px;
  margin: 0 auto 20px auto;
}
.territory-all-regions-box-main-title {
  color: #FFF;
  text-align: center;
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 10px;
}
.territory-all-regions-box-main-content {
  color: #FFF;
  text-align: center;
  font-size: 1.125rem;
  font-weight: 400;
}
.territory-all-regions-box-main-content p{
  color: #FFF;
  text-align: center;
  font-size: 1.125rem;
  font-weight: 400;
  margin-bottom: 10px;
}
.territory-all-regions-box-main-content a{
  color: #FFF;
  text-align: center;
  font-size: 1.125rem;
  font-weight: 400;
}
.territory-all-regions-boxes {
  display: flex;
  grid-gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
}
.territory-all-regions-box {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #0A38A6;
  width: 460px;
  height: 220px;
  text-decoration: none;
}
.territory-all-regions-box-text {
  color: #FFF;
  text-align: center;
  font-size: 1.563rem;
  font-weight: 700;
  padding: 20px;
}

.territory-all-regions-box:nth-child(2n) {
  background: #082F89;
}

.territory-all-regions-box:nth-child(3n) {
  background: #08266D;
}

.territory-all-regions-box:nth-child(4n) {
  background: #0A38A6;
}

.territory-all-regions-box:nth-child(5n) {
  background: #082F89;
}

.territory-all-regions-box:nth-child(6n) {
  background: #08266D;
}

.territory-all-regions-box:nth-child(7n) {
  background: #0A38A6;
}

.territory-all-regions-box:nth-child(8n) {
  background: #082F89;
}

.territory-all-regions-box:nth-child(9n) {
  background: #08266D;
}
