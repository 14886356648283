.online {
  padding: 46px 0 92px 0;
  background: url("/img/content-bg.png") 100% 46px no-repeat;
}

.online-content {
  margin-top: 30px;
}

.online-content form{
  display: flex;
  flex-direction: column;
  align-items: start;
  color: #1B1B1B;
  font-size: 1.125rem;
  font-weight: 700;
}

.form-input-box {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  width: 100%;
  margin-bottom: 20px;
}

.main-block-input input{
  background: none;
  border-radius: 10px;
  border: 1px solid #D1D1D1;
}

.textarea-box {
  display: flex;
  flex-direction: column;
}

.form-input-box-textarea {
  width: 100%;
  margin-bottom: 42px;
}

.form-input-box-textarea textarea{
  border-radius: 10px;
  border: 1px solid #D1D1D1;
}

.form-main-btn {
  border-radius: 25px;
  border: 3px solid #000;
  background: none;
  color: #020202;
  text-align: center;
  font-size: 1.125rem;
  font-weight: 700;
  padding: 14px 75px;
}

.online .modal{

}

.online .modal-header {
  justify-content: end;
  border: none;
}

.online .modal-header svg{
  cursor: pointer;
}

.online .modal-title{
  color: #06297B;
  text-align: center;
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 23px;
  font-style: normal;
  line-height: normal;
}

.online .modal-body{
  color: #000;
  text-align: center;
  font-size: 1.25rem;
  margin-bottom: 35px;
}

.online .modal-footer {
  justify-content: center;
  border: none;
}

.online .modal-dialog.modal-dialog-centered {
  max-width: 600px;
}

.online .modal-content {
  border-right: 10px;
}

.online .modal-footer {
  margin-bottom: 73px;
}

@media (max-width: 992px) {
  .form-input-box {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 576px) {
  .online .modal-title {
    font-size: 1.875rem;
  }
  .online .modal-footer button{
    font-size: 1.25rem;
  }
}
