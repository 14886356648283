.symbolic {
  padding: 46px 0 95px 0;
  background: url("/img/content-bg.png") 100% 46px no-repeat;
  font-size: 1.25rem;
  color: #000;

  // .symbolic__content

  &__content {
    margin-top: 52px;
  }

  // .symbolic__content-text

  &__content-text {
    margin-bottom: 50px;
  }

  &__content-text a {
    font-size: 1.25rem;
    color: #000;
    text-decoration: none;
  }

  // .symbolic__content-images

  &__content-images {
    //display: grid;
    //grid-template-columns: repeat(3, 1fr);
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    grid-gap: 20px;
  }

  // .symbolic__content-box-img

  &__content-box-img {
    border-radius: 10px;
  }

  // .symbolic__content-img

  &__content-img {
    border-radius: 10px;
  }
}

@media (max-width: 576px) {
  .symbolic {
    &__content-box-img {
      width: 100%;
    }

    &__content-img {
      width: 100%;
    }
  }
}

