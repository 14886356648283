.service {
	padding: 46px 0 140px 0;
	background: url("/img/content-bg.png") 100% 46px no-repeat;
}

.service-content {
	margin-top: 98px;
}

.service-content-title {
	color: #000;
	text-align: center;
	font-size: 1.875rem;
	font-style: normal;
	font-weight: 700;
	margin-bottom: 43px;
}

.service-content-box-wrapper {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 20px;
	margin-bottom: 43.5px;
}

.service-content-box-wrapper-bottom {
	display: flex;
	margin-bottom: 90px;
	grid-gap: 20px;
	justify-content: center;
}

.service-content-box {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 10px;
	background: #FFF;
	box-shadow: 4px 0 15px 0 rgba(0, 0, 0, 0.25);
	padding: 37px 40px;
	text-align: center;
	color: #06297B;
	font-size: 1.25rem;
	font-weight: 700;
}

.service-content-box-bottom {
	width: 340px;
}

.service-content-box--blue {
	background: #021B54;
	width: 340px;
	color: #fff;
	padding: 27px 40px;
	margin: 0 auto 56px auto;
	font-size: 1.563rem;
	font-weight: 700;
}

.service-sub-content-sub-box-wrapper {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 20px;
}

.service-sub-content-sub-box {
	text-align: center;
	padding: 25px 30px;
	color: #FFF;
	font-size: 1.25rem;
	font-weight: 700;
	border-radius: 10px;
}

.service-sub-content-sub-box.blue-one {
	background: #082F89;
}

.service-sub-content-sub-box.blue-second {
	background: #082F89;
}

.service-sub-content-sub-box.blue-third {
	background: #08266D;
}

.service-sub-content {

		// .service-sub-content__title

		&__title {
			color: #000;
			margin: 0 auto 37.5px auto;
			font-size: 1.875rem;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			width: 394px;
			text-align: center;
		}
}

@media (max-width: 1250px) {
	.service-content-box-wrapper {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media (max-width: 992px) {
	.service-content-box-wrapper {
		//grid-template-columns: repeat(2, 1fr);
	}

	.service-content-box-wrapper-bottom {
		flex-direction: column;
	}

	.service-content-box-bottom {
		width: 100%;
	}

	.service-sub-content-sub-box-wrapper {
		grid-template-columns: repeat(1,1fr)
	}
}

@media (max-width: 676px) {
	.service-content-box-wrapper {
		grid-template-columns: repeat(1, 1fr);
		margin-bottom: 20px;
	}

	.service-content-title {
		margin-bottom: 35px;
		width: 100%;
		text-align: start;
	}

	.service-sub-content {

		// .service-sub-content__title

		&__title {
			margin-bottom: 35px;
			width: 100%;
			text-align: start;
		}
	}
}

@media (max-width: 400px) {
	.service-content-box.service-content-box--blue {
		width: 100%;
	}
}
