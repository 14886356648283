.coordinators {
  padding: 46px 0 131px 0;
  background: url("/img/content-bg.png") 100% 46px no-repeat;
}

.coordinators-content {

}
.sub-main-text {
}
.coordinators-content-boxes {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}
.coordinators-content-box {
  text-decoration: none;
  color: #FFF;
  font-size: 1.563rem;
  font-weight: 700;
  padding: 80px 0;
  border-radius: 10px;
  background: #0A38A6;
  text-align: center;
}
.coordinators-content-box-title {
  margin: 0 auto;
  max-width: 40%;
}

.coordinators-content-box:nth-child(2n) {
  background: #082F89;
}
.coordinators-content-box:nth-child(3n) {
  background: #08266D;
}

.coordinators-content-box:nth-child(4n) {
  background: #0A38A6;
}
.coordinators-content-box:nth-child(5n) {
  background: #082F89;
}
.coordinators-content-box:nth-child(6n) {
  background: #08266D;
}

.coordinators-content-box:nth-child(7n) {
  background: #0A38A6;
}
.coordinators-content-box:nth-child(8n) {
  background: #082F89;
}
.coordinators-content-box:nth-child(9n) {
  background: #08266D;
}

@media (max-width: 992px) {
  .coordinators-content-boxes {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 576px) {
  .coordinators-content-boxes {
    grid-template-columns: repeat(1, 1fr);
  }
}
