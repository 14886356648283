.history {
  padding: 46px 0 170px 0;
  background: url("/img/content-bg.png") 100% 46px no-repeat;
}

.history-wrapper-content {
  color: #000;
  font-size: 1.25rem;
  margin-top: 84px;
}

.history-wrapper-content .history-wrapper-content-box:not(:last-child){
  margin-bottom: 44px;
}
.history-wrapper-content-box {
  display: flex;
}

.history-wrapper-content-date {
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  background: #F3F0F0;
  padding: 8px 10px;
  font-size: 0.875rem;
  margin-right: 19px;
}
.history-wrapper-content-date-number {
  font-size: 1.625rem;
  font-weight: 700;
}
.history-wrapper-content-date-month {
}
.history-wrapper-content-date-year {
}
.history-wrapper-content-text-box {
  max-width: 85%;
}
.history-wrapper-content-text {
}

@media (max-width: 576px) {
  .history-wrapper-content-box {
    flex-direction: column;
  }

  .history-wrapper-content-date {
    font-size: 1.875rem;
    flex-direction: row;
    justify-content: center;
    margin-right: 0;
    margin-bottom: 16px;
  }

  .history-wrapper-content-date-number {
    margin-right: 10px;
  }

  .history-wrapper-content-date-month {
    margin-right: 10px;
  }

  .history-wrapper-content-text-box {
    width: 100%;
  }
}
