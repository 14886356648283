.purchase {
  padding: 46px 0 133px 0;
  background: url("/img/content-bg.png") 100% 46px no-repeat;
}
.purchase-wrapper {
}
.title {
}
.main-title {
}
.line-title {
}
.box-cards {
}
.box-card {
}
.card-main-title {
}
.card-main-title-purchase {
  margin-bottom: 73px;
}
.card-main-link {
}
