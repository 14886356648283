.territory-region {
  padding: 46px 0 0 0;
  background: url("/img/content-bg.png") 100% 46px no-repeat;
}

.territory-region-content {
}
.sub-main-text {
}
.territory-region-content-boxes {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  margin-top: 41px;
  margin-bottom: 103px;
}
.territory-region-content-box {
  border-radius: 10px;
  border: 1px solid #E6E6E6;
  background: #0A38A6;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  padding: 31px 13px 28px;
  color: #FFF;
  text-align: center;
}

.territory-region-content-box:nth-child(2n) {
  background: #082F89;
}
.territory-region-content-box:nth-child(3n) {
  background: #08266D;
}
.territory-region-content-box:nth-child(4n) {
  background: #021B54;
}
.territory-region-content-box:nth-child(6n) {
  background: #082F89;
}
.territory-region-content-box:nth-child(7n) {
  background: #08266D;
}
.territory-region-content-box:nth-child(8n) {
  background: #021B54;
}
.territory-region-content-box-title {
  font-weight: 700;
  font-size: 1.5rem;
  margin-bottom: 12px;
}
.territory-region-content-box-title-line {
  width: 120px;
  height: 4px;
  background: #fff;
  margin: 12px auto 0 auto;
}
.territory-region-content-text {
  font-weight: 400;
  font-size: 1.125rem;
}

.territory-region-content-text p:not(:last-child){
  margin-bottom: 43px;
}

.map {
  width: 100%;
  max-height: 500px;
}

.map iframe{
  width: 100%;
}


@media (max-width: 1400px) {
  .territory-region-content-boxes {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 992px) {
  .territory-region-content-boxes {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .territory-region-content-boxes {
    grid-template-columns: repeat(1, 1fr);
  }
}
