.vacancy {
  padding: 46px 0 95px 0;
  background: url("/img/content-bg.png") 100% 46px no-repeat;
}

.one-content {
  color: #000;
  font-size: 1.25rem;
  margin-top: 60px;
}

.one-content-text a{
  color: #000;
  font-size: 1.25rem;
  text-decoration: none;
}
