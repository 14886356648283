.standard-base {
  padding: 46px 0 66px;
  background: url("/img/content-bg.png") 100% 46px no-repeat;
}

.standard-base-wrapper-content {
  margin-top: 77px;
}
.standard-base-wrapper-content-box-link {
  font-size: 1.125rem;
  text-decoration: none;
  color: #000;
  display: flex;
  margin-bottom: 26px;
}

.standard-base-wrapper-content-box-link:hover {
  color: #000!important;
}

.standard-base-wrapper-content-box-link svg {
  margin-right: 21px;
}
