.contacts-centre {
  padding: 46px 0 94px 0;
  background: url("/img/content-bg.png") 100% 46px no-repeat;
}

.contacts-centre-content {
  font-size: 1.5rem;
}
.contacts-centre-content .sub-main-text {
  margin-top: 19px;
  margin-bottom: 11px;
}
.main-phone {
  color: #000;
  font-size: 1.625rem;
  font-weight: 700;
}
.contacts-centre-content-info {
  margin-top: 53px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contacts-centre-content-info-text {
  color: #000;
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 51px;
  text-align: center;
}
.contacts-centre-content-info-boxes {
  display: flex;
  grid-gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
}

.contacts-centre-content-info-box {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  color: #FFF;
  text-align: center;
  font-size: 1.5rem;
  border-radius: 10px;
  background: #082F89;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  padding: 33px 50px;
  min-height: 200px;
  width: 338px;
}
.contacts-centre-content-info-box-title {
  font-weight: 700;
  margin-bottom: 7px;
}
.contacts-centre-content-info-box-text {
  font-weight: 400;
}
.contacts-centre-content-info-box-text p{
  margin-bottom: 11px;
}

.contacts-centre-content-info-sub-text {
  color: #000;
  text-align: center;
  font-size: 2.25rem;
  font-weight: 500;
}

@media (max-width: 1400px) {
  .contacts-centre-content-info-sub-box {
    //width: 25%;
  }
}

@media (max-width: 992px) {
  .contacts-centre-content-info-boxes {
  }
}

@media (max-width: 576px) {
  .contacts-centre-content-info-boxes {
    width: 100%;
    justify-content: unset;
    flex-direction: column;
  }

  .contacts-centre-content-info-box {
    width: 100%;
  }

  .contacts-centre-content-info-text {
    max-width: 100%;
    word-break: break-word;
  }
}
