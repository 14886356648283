.acts {
  padding: 46px 0 84px 0;
  background: url("/img/content-bg.png") 100% 46px no-repeat;
}

.acts-content {
  margin-top: 51px;
  font-size: 1.125rem;
}
.block-links {
}
.box-link {
}
.box-link-svg {
}
.box-link-text {
}
