.news {
  padding: 46px 0 107px 0;
  background: url("/img/content-bg.png") 100% 46px no-repeat;
}

.news-content {
  margin-top: 29px;
  color: #000;
  font-size: 1.25rem;
}

.block-cards {
}
.block-card {
}
.block-card-img {
}
.block-card-content {
}
.block-card-content-date {
}
.block-card-content-date-number {
}
.block-card-content-date-month {
}
.block-card-content-date-year {
}
.block-card-content-title {
}
