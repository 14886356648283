.vacancies {
  padding: 46px 0 94px 0;
  background: url("/img/content-bg.png") 100% 46px no-repeat;
}
.box-cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 31px 18px;
  color: #000;
  font-size: 1.25rem;
  margin-top: 70px;
}
.box-card {
  padding: 28px 13px 16px 13px;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 4px 0 15px 0 rgba(0, 0, 0, 0.25);
}
.card-main-title {
  font-size: 1.875rem;
  font-weight: 700;
  margin-bottom: 13px;
}
.card-city {
  margin-bottom: 42px;
}
.card-main-link {
  color: #000;
}

@media (max-width: 1200px) {
  .box-cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 576px) {
  .box-cards {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 21px;
  }
}
