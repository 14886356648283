.partners {
  padding: 46px 0 95px 0;
  background: url("/img/content-bg.png") 100% 46px no-repeat;
}

.division-cards {
  margin-top: 63px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 20px;
  color: #111;
  font-size: 1rem;
}
.division-card {
  border-radius: 10px;
  border: 1px solid #D9D9D9;
  background: #FFF;
  padding: 17px 6px 9px;
  text-align: center;
  text-decoration: none;
  color: #111;
}
.division-card-img {
  height: 80px;
}
.division-card-text {
}

@media (max-width: 1356px) {
  .division-cards {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (max-width: 1176px) {
  .division-cards {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 992px) {
  .division-cards {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 767px) {
  .division-cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 568px) {
  .division-cards {
    grid-template-columns: repeat(1, 1fr);
  }
}
