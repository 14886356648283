.one-announcement {
  padding: 11px 0 77px 0;
  background: url("/img/content-bg.png") 100% 46px no-repeat;
}

.back-page-btn {
  display: inline-block;
  color: #000;
  font-size: 1.25rem;
  font-style: italic;
  font-weight: 700;
  margin-bottom: 44px;
  text-decoration: none;
}

.back-page-btn:hover {
  color: #000!important;
}

.title-data {
  display: inline-block;
  color: #000;
  font-size: 1.25rem;
  font-style: italic;
  font-weight: 700;
  margin-bottom: 11px;
}

.one-announcement-content {
  margin-top: 52px;
  margin-bottom: 40px;
  color: #000;
  font-size: 1.25rem;
}
.content-block-img {
  float: right;
  border-radius: 10px;
  margin-left: 20px;
  margin-bottom: 20px;
}
.content-img {
  border-radius: 10px;
}
.content-block-text {
}
.content-text {
}

.content-text p{
  margin-bottom: 25px;
}
.block-social {
  display: flex;
}

.block-social a:not(:last-child){
  margin-right: 4px;
}

.block-social-link {
  display: block;
  text-decoration: none;
}

.block-social-link:last-child {
  background: #06297B;;
}

@media (max-width: 1200px) {
  .one-announcement-content {
    display: flex;
    flex-direction: column;
  }

  .content-block-img {
    margin: 0 auto 40px auto;
    order: 2;
  }

  .content-img {
    width: 100%;
  }
}
