.docs {
  padding: 46px 0 84px 0;
  background: url("/img/content-bg.png") 100% 46px no-repeat;
}

.docs-content {
  margin-top: 51px;
  font-size: 1.125rem;
}
.block-links {

}
.box-link {
  display: flex;
  align-items: center;
  color: #000;
  text-decoration: none;
  margin-bottom: 8px;
}
.box-link:hover {
  color: #000!important;
  text-decoration: underline;
}

.box-link-text {
}

@media (max-width: 576px) {
  .box-link {
    align-items: start;
    margin-bottom: 20px;
  }
}
