@font-face {
  font-weight: 400; /* Соответствует значению normal */
  font-family: Inter;
  src: url("../font/Inter-Regular.woff");
}

@font-face {
  font-weight: 500; /* Соответствует значению normal */
  font-family: Inter;
  src: url("../font/Inter-Medium.woff");
}

@font-face {
  font-weight: 600; /* Соответствует значению normal */
  font-family: Inter;
  src: url("../font/Inter-SemiBold.woff");
}

@font-face {
  font-weight: 700; /* Соответствует значению normal */
  font-family: Inter;
  src: url("../font/Inter-Bold.woff");
}

@font-face {
  font-weight: 800; /* Соответствует значению normal */
  font-family: Inter;
  src: url("../font/Inter-ExtraBold.woff");
}


