.contact-territory {
  padding: 46px 0 98px 0;
  background: url("/img/content-bg.png") 100% 46px no-repeat;
}

.contact-territory-content {
  margin-bottom: 19px;
}
.sub-main-text {
}
.contact-territory-content-boxes {
  margin-top: 43px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}
.contact-territory-content-box {
  border-radius: 10px;
  background: #0A38A6;
  padding: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-territory-content-box:nth-child(2n) {
  background: #082F89;
}
.contact-territory-content-box:nth-child(3n) {
  background: #08266D;
}
.contact-territory-content-text {
  color: #FFF;
  text-align: center;
  font-size: 1.563rem;
  font-weight: 700;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .contact-territory-content-boxes {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .contact-territory-content-boxes {
    grid-template-columns: repeat(1, 1fr);
  }
}
