.one-news {
  padding: 11px 0 77px 0;
  background: url("/img/content-bg.png") 100% 46px no-repeat;
}


.one-news-content {
  margin-top: 52px;
  color: #000;
  font-size: 1.25rem;
}
.content-block-img {
}
.content-img {
}
.content-block-text {
}
.content-text {
}
.block-social {
}
.block-social-link {
}

@media (max-width: 1200px) {
  .one-news-content {
    display: flex;
    flex-direction: column;
  }
}
