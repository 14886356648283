.announcements {
  padding: 46px 0 77px 0;
  background: url("/img/content-bg.png") 100% 46px no-repeat;
}

.announcements-content {
  color: #000;
  font-size: 1.25rem;
}
.sub-main-text {
  margin-top: 29px;
  margin-bottom: 56px;
  color: #000;
  font-size: 1.25rem;
  max-width: 75%;
}
.block-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 25px;
}
.block-card {
  width: 100%;
  color: #000;
  font-size: 1.125rem;
  font-weight: 700;
  text-decoration: none;
}

.block-card:hover{
  color: #000!important;
}
.block-card-img {
  border-radius: 10px 10px 0 0;
}
.block-card-img img{
  border-radius: 10px 10px 0 0;
  width: 100%;
}
.block-card-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 10px 14px;
  border-radius: 0 0 10px 10px  ;
  border: 1px solid #ECECEC;
}
.block-card-content-date {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  background: #F3F0F0;
  font-size: 0.875rem;
  margin-right: 19px;
  padding: 10px;
}
.block-card-content-date-number {
  font-size: 1.625rem;
}
.block-card-content-date-month {
}
.block-card-content-date-year {
}
.block-card-content-title {
}

@media (max-width: 992px) {
  .block-cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .block-cards {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 21px;
  }

  .sub-main-text {
    max-width: 100%;
  }
}
