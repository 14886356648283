.about {
  padding: 46px 0 100px;
  background: url("/img/content-bg.png") 100% 6px no-repeat;
}

.about-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 26px;
  margin-top: 55px;
  // .about-content__left

  &__left {
  }

  // .about-content__text

  &__text {
    color: #000;
    font-size: 1.25rem;
  }

  &__text p{
    color: #000;
    margin-bottom: 20px;
  }

  &__text a{
    color: #000;
    text-decoration: none;
  }

  // .about-content__right

  &__right {
  }
}

.about-content-img {
}

@media (max-width: 1300px) {
  .about-content {
    grid-template-columns: repeat(1, 1fr);
  }

  .about-content__left {
    order: 2;
  }

  .about-content__right {
    text-align: center;
  }

  .about-content__right img {
    max-width: 100%;
  }
}
